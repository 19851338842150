import { Button, ButtonProps } from '@chakra-ui/react'

import { theme } from '~/styles/theme'
import Card from '../common/Card'

const HeaderButton = (props: ButtonProps) => {
  return (
    <Card>
      <Button
        textDecoration="none"
        _hover={{ bgColor: 'rgba(255, 255, 255, 0.06)' }}
        borderWidth="1px"
        borderRadius="10px"
        borderColor="linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.12) 50%, rgba(255,255,255,0.4) 100%)"
        background={theme.colors.background.box}
        sx={{
          fontVariant: 'tabular-nums',
        }}
        {...props}
      />
    </Card>
  )
}

export default HeaderButton
