import { Address } from 'viem'

import { isDevEnv } from '~/helpers/env'
import { Network } from '~/lib/networks'

export interface Token {
  name: string
  symbol: string
  address: Address
  underlyingSymbol: string
  underlyingDecimals: number
  isNativeToken?: boolean
  isWrappedNativeToken?: boolean // This market is the wrapped version of the native token of the network
  isPriceAnchor?: boolean // If a market has this flag means its underlying price should be 1 USD and can be used to calculate prices at old oracle implementations
  isDistributionToken?: boolean
  shouldUseBufferForGas?: boolean
  supplyCapUSD?: number
  borrowCapUSD?: number
}

export const CTOKEN_DECIMALS = 8
export const UNDERLYING_PRICE_BASE_DECIMALS = 36

export const DISTRIBUTION_TOKEN_UNDERLYING_SYMBOL = 'DEEPR'
export const NATIVE_NETWORK_IOTA_TOKEN_SYMBOL = 'IOTA'

const SHIMMER_TESTNET_TOKENS: Record<string, Token> = {
  '0x5A634DEb1cE6497f378a5345457Ef0afB5850122': {
    name: 'Deepr SMR',
    symbol: 'dWSMR',
    address: '0x5A634DEb1cE6497f378a5345457Ef0afB5850122',
    underlyingSymbol: 'wSMR',
    underlyingDecimals: 6,
    shouldUseBufferForGas: true,
  },
  '0x1104B75788bfe8C0E5a04fF32F37596445172f7D': {
    name: 'Deepr DEEPR',
    symbol: 'dDEEPR',
    address: '0x1104B75788bfe8C0E5a04fF32F37596445172f7D',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0x3AdD97Be8089eAe605398632C1235CF4Aa406f3D': {
    name: 'Deepr WBTC',
    symbol: 'dWBTC',
    address: '0x3AdD97Be8089eAe605398632C1235CF4Aa406f3D',
    underlyingSymbol: 'fBTC',
    underlyingDecimals: 8,
  },
  '0xe2914603529d2aaa5d2720BA22AA78875C30A54b': {
    name: 'Deepr WETH',
    symbol: 'dWETH',
    address: '0xe2914603529d2aaa5d2720BA22AA78875C30A54b',
    underlyingSymbol: 'fETH',
    underlyingDecimals: 18,
  },
  '0x53d91062900c7eab45907aD4ba2372D5F25b4134': {
    name: 'Deepr USDT',
    symbol: 'dUSDT',
    address: '0x53d91062900c7eab45907aD4ba2372D5F25b4134',
    underlyingSymbol: 'fUSDT',
    underlyingDecimals: 6,
  },
}

const SHIMMER_MAINNET_TOKENS: Record<string, Token> = {
  '0x52F2a6f2B6151245f7F864F4E4aCC206202E4e6a': {
    name: 'Deepr SMR',
    symbol: 'dWSMR',
    address: '0x52F2a6f2B6151245f7F864F4E4aCC206202E4e6a',
    underlyingSymbol: 'SMR',
    underlyingDecimals: 6,
    shouldUseBufferForGas: true,
  },
  '0x811B91c9bA36e1f00EA5F81eC335E03461C86f40': {
    name: 'Deepr DEEPR',
    symbol: 'dDEEPR',
    address: '0x811B91c9bA36e1f00EA5F81eC335E03461C86f40',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0xD631a748285e87a008aC02af005a21aC7042aADE': {
    name: 'Deepr USDT',
    symbol: 'dUSDT',
    address: '0xD631a748285e87a008aC02af005a21aC7042aADE',
    underlyingSymbol: 'USDT',
    underlyingDecimals: 6,
  },
}

const IOTA_TESTNET_TOKENS: Record<string, Token> = {
  '0x6cBeB964328D88D17Ab422c2c01de0c1CA7E5Fd6': {
    name: 'Deepr Wrapped IOTA',
    symbol: 'dfWIOTA',
    address: '0x6cBeB964328D88D17Ab422c2c01de0c1CA7E5Fd6',
    underlyingSymbol: 'wIOTA',
    underlyingDecimals: 18,
    isWrappedNativeToken: true,
  },
  '0x019C5dD78E98b7DC92e789444bFBFb94F890cF4e': {
    name: 'Deepr DEEPR',
    symbol: 'dDEEPR',
    address: '0x019C5dD78E98b7DC92e789444bFBFb94F890cF4e',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0xB4Cc826082A90dbA5091a66cE09AbB6aCEAF98C9': {
    name: 'Deepr WBTC',
    symbol: 'dfWBTC',
    address: '0xB4Cc826082A90dbA5091a66cE09AbB6aCEAF98C9',
    underlyingSymbol: 'fBTC',
    underlyingDecimals: 8,
  },
  '0x1E050557cE90A60fa91068988BEc046c31C968f0': {
    name: 'Deepr WETH',
    symbol: 'dfWETH',
    address: '0x1E050557cE90A60fa91068988BEc046c31C968f0',
    underlyingSymbol: 'fETH',
    underlyingDecimals: 18,
  },
  '0xa1148404F9EE3456204a6A607534c649acCa3c23': {
    name: 'Deepr USDT',
    symbol: 'dfUSDT',
    address: '0xa1148404F9EE3456204a6A607534c649acCa3c23',
    underlyingSymbol: 'fUSDT',
    underlyingDecimals: 6,
  },
}

const IOTA_TOKENS: Record<string, Token> = {
  '0x260817581206317E2665080A2E66854e922269d0': {
    name: 'Deepr Wrapped IOTA',
    symbol: 'dWIOTA',
    address: '0x260817581206317E2665080A2E66854e922269d0',
    underlyingSymbol: 'wIOTA',
    underlyingDecimals: 18,
    isWrappedNativeToken: true,
  },
  '0x5041AF7b6461717D97ea3422dFC61553c1F8f8b4': {
    name: 'Deepr DEEPR',
    symbol: 'dDEEPR',
    address: '0x5041AF7b6461717D97ea3422dFC61553c1F8f8b4',
    underlyingSymbol: 'DEEPR',
    underlyingDecimals: 18,
    isDistributionToken: true,
  },
  '0x0f571c5F3c328f57CDDDF204D752C7Ddb49686d5': {
    name: 'Deepr WETH',
    symbol: 'dWETH',
    address: '0x0f571c5F3c328f57CDDDF204D752C7Ddb49686d5',
    underlyingSymbol: 'wETH',
    underlyingDecimals: 18,
  },
  '0xf96AdBfcd3EB9fd61faDa3F2eD70Ac47e3E1e045': {
    name: 'Deepr USDC',
    symbol: 'dUSDC',
    address: '0xf96AdBfcd3EB9fd61faDa3F2eD70Ac47e3E1e045',
    underlyingSymbol: 'USDC.e',
    underlyingDecimals: 6,
  },
}

export const tokens: {
  [key: number]: {
    [key: Address]: Token
  }
} = {
  [Network.Shimmer]: SHIMMER_MAINNET_TOKENS,
  [Network.IOTA]: IOTA_TOKENS,
}

if (isDevEnv) {
  tokens[Network.Shimmer_Testnet] = SHIMMER_TESTNET_TOKENS
  tokens[Network.IOTA_Testnet] = IOTA_TESTNET_TOKENS
}

export const SUPPORTED_NETWORKS = Object.keys(tokens).map((key) => Number(key) as Network)
