import { useCallback } from 'react'
import { erc20Abi } from 'viem'
import { useReadContract, useWalletClient } from 'wagmi'

import { CTOKEN_DECIMALS } from '~/constants/tokens'
import * as Analytics from '~/lib/analytics'
import { LensMarket } from '~/types'

export const useAddCTokenToWallet = (cToken?: LensMarket['cToken']): (() => Promise<boolean>) => {
  const { data: walletClient } = useWalletClient()

  const { data: symbol } = useReadContract({
    functionName: 'symbol',
    abi: erc20Abi,
    address: cToken,
    query: {
      enabled: !!cToken,
    },
  })

  const addCTokenToWallet = useCallback(async () => {
    try {
      if (!cToken) {
        throw new Error('No cToken address to add')
      }

      if (!walletClient) {
        throw new Error('No walletClient available')
      }

      if (!symbol) {
        throw new Error('Unknown symbol')
      }

      await walletClient.watchAsset({
        type: 'ERC20',
        options: {
          address: cToken,
          symbol,
          decimals: CTOKEN_DECIMALS,
        },
      })

      return true
    } catch (e) {
      Analytics.trackException(e)

      return false
    }
  }, [cToken, symbol, walletClient])

  return addCTokenToWallet
}
