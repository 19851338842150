export const en = {
  layout: {
    meta: {
      title: 'Deepr Finance',
      description:
        'Deep liquidity on the most secure and efficient lending platform on IOTA and Shimmer. Deepr Finance provides decentralized and transparent lending markets for passive income.',
    },
    navigation: {
      markets: 'Markets',
      liquidate: 'Liquidate',
      subieMint: 'Subie NFT',
      docs: 'Docs',
      staking: 'Staking',
      dashboard: 'Dashboard',
      terms: 'Terms of Service',
      launchApp: 'Launch app',
      blog: 'Blog',
      connectWallet: 'Connect Wallet',
      faq: 'FAQ',
      faucet: 'Faucet',
      bridge: 'Bridge',
    },
    blockNumber: 'Latest block: {{ blockNumber }}',
    authenticate: 'Connect Wallet',
    logout: 'Logout',
    switchNetwork: 'Switch Network',
    yourNetwork: 'Your network',
    notConnectedToNetwork:
      '{{ unsupportedNetwork }} is not supported. Please change your network to {{ supportedNetwork }}.',

    claimModal: {
      balance: 'balance',
      walletBalance: 'Wallet Balance',
      unclaimed: 'Unclaimed Balance',
      claim: 'Claim',
      deeprTokenDefaultSymbol: 'DPR',
      deeprTokenName: 'DEEPR',
      noUnclaimedBalance: 'No Unclaimed Balance',
      price: 'Price',
      addTokenToWallet: 'Add DEEPR token to wallet',
      approveInWallet: 'Approve transaction in wallet',
      transactionPending: 'Transaction pending',
    },
    networkModal: {
      title: 'Switch Networks',
      approve: 'Approve',
      connected: 'Connected',
    },
    notFound: {
      '404': '404',
      pageTitle: 'Deepr - Page not found',
      body: "If you are looking for the lost city... it's not here.",
    },
  },
  home: {
    hero: {
      titleA: 'Discover the Potential of Decentralized Lending',
      description: 'Bringing a Secure and Efficient Lending Platform \nto IOTA and Shimmer',
      getStarted: 'Get Started',
      learnMore: 'Learn More',
      version: 'Beta v1.3',
    },
    stats: {
      marketsTotal: 'Money markets',
      marketSize: 'Market size',
      averageApy: 'Average APY',
    },
    cryptoInvestors: {
      features: {
        lendAndBorrow: {
          title: 'Lend and borrow assets with ease',
          description: 'Find the best opportunities for earning and investing',
        },
        reliable: {
          title: 'Reliable and secure',
          auditedBy: 'Audited by',
          hashex: 'Hashex',
          connector: 'and',
          sherlock: 'Sherlock',
        },
        fast: {
          title: 'Fast and Efficient',
          description: 'Unleashed on IOTA \nand Shimmer networks',
        },
        streamlined: {
          title: 'Streamlined Experience',
          description: 'Navigate your investments with confidence using Lighthouse',
        },
      },
      learnMore: 'Learn more',
    },
    socials: {
      title: 'Stay up to date',
      twitterX: {
        description: 'Follow Us on X for the Latest News and Updates',
        button: 'Follow on X',
      },
      discord: {
        description: 'Connect with Our Community and Be a Part of the Conversation',
        button: 'Join Discord',
      },
    },
    join: {
      title: 'Join the Future of Finance',
      titleWithLineBreak: 'Join the Future\nof Finance',
      launchApp: 'Launch App',
    },
    guides: {
      title: 'New to crypto lending platforms?',
      wallet: {
        title: 'Setting up a wallet',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      lending: {
        title: 'Lending guide',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      borrowing: {
        title: 'Borrowing guide',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
    },
  },
  markets: {
    title: 'Markets',
    supply: 'Supply',
    borrow: 'Borrow',
    total: 'Total {{ category }}',
    marketsTable: {
      asset: 'Asset',
      totalSupply: 'Total Supply',
      supplyAPY: 'Supply APY',
      totalBorrows: 'Total Borrow',
      borrowAPR: 'Borrow APR',
    },
    stats: {
      '24hrVol': '24 hour volume',
      supply: 'Suppliers',
      borrow: 'Borrowers',
    },
  },
  detail: {
    detailBox: {
      price: 'Price',
      marketLiquidity: 'Market liquidity',
      borrowCap: '{{ symbol }} borrow cap',
      borrowCapSkeleton: 'Borrow cap',
      reserves: 'Reserves',
      reserveFactor: 'Reserve factor',
      collateralFactor: 'Collateral factor',
      interestPaidDay: 'Interest paid/day',
      minted: '{{ symbol }} minted',
      mintedSkeleton: 'Minted',
      exchangeRate: 'Exchange rate',
      noLimit: 'No limit',
    },
    supplyBorrowChart: {
      supply: 'Supply',
      borrow: 'Borrow',
      netRate: 'Net rate',
      supplyApy: 'Supply APY',
      borrowApr: 'Borrow APR',
      distributionApy: 'Distribution APY',
      totalSupply: 'Total supply',
      totalBorrow: 'Total borrow',
      suppliers: 'Suppliers',
      borrowers: 'Borrowers',
      notEnoughData: 'There is not enough data to display this chart.',
    },
    interestChart: {
      utilizationVsApy: 'Utilization vs APY/APR',
      current: 'Current',
      notEnoughData: 'There is not enough data to display this chart.',
    },
    tooltip: {
      totalSupply: 'Total Supply',
      totalBorrow: 'Total Borrow',
      supplyAPY: 'Supply APY',
      borrowAPR: 'Borrow APR',
    },
  },
  dashboard: {
    title: 'Dashboard',
    lighthouse: {
      title: 'Lighthouse',
      supplyBalance: 'Supply Balance',
      borrowBalance: 'Borrow Balance',
      netApy: 'Net APY',
      borrowLimit: 'Borrow Limit',
      outlook: 'Outlook',
      learnHow: 'Learn How',
      tutorial: 'View tutorial',
      safetyLabel: 'Safety',
      tooltips: {
        title: 'Health status of the account. If in risk, it can be liquidated',
        borrowLimit: 'Max amount a user can borrow in USD',
        netApy: 'Interest earn & paid, plus Deepr rewards',
      },
      safety: {
        highest: {
          title: 'Great',
          description: 'Time to start your grand journey by enabling collateral and supplying funds.',
        },
        medium: {
          title: 'Great',
          description: 'Everything looks clear and calm, shipmate.',
        },
        low: {
          title: 'Cloudy',
          description: "Okay for now cap'n. But watch out for liquidation risk.",
        },
        critical: {
          title: 'Danger',
          description: 'Secure your investment and avoid risk of liquidation.',
        },
        initial: {
          title: 'Get Started',
          description:
            'Start your journey by connecting your wallet and enabling collateral. When you add funds, Lighthouse will act as your investment beacon.',
        },
      },
    },
    marketsTable: {
      asset: 'Asset',
      apy: 'APY',
      apr: 'APR',
      earned: 'Earned',
      balance: 'Balance',
      wallet: 'Wallet',
      collateral: 'Collateral',
      accrued: 'Accrued',
      liquidity: 'Liquidity',
    },
    supplyMarkets: 'Supply Markets',
    supplyingMarkets: 'Supplied',
    borrowMarkets: 'Borrow Markets',
    borrowingMarkets: 'Borrowing',
    borrowLimitUsed: 'Borrow Limit Used',
    comptrollerErrors: {
      generic: 'Comptroller Rejection',
      noError: 'Not a failure',
      unauthorized: 'The sender is not authorized to perform this action',
      comptrollerMismatch: 'Liquidation cannot be performed in markets with different comptrollers',
      insufficientShortfall: 'The account does not have sufficient shortfall to perform this action',
      insufficientLiquidity: 'The account does not have sufficient liquidity to perform this action',
      invalidCloseFactor: 'The close factor is not valid',
      invalidCollateralFactor: 'The collateral factor is not valid',
      invalidLiquidationIncentive: 'The liquidation incentive is invalid',
      marketNotEntered: 'The market has not been entered by the account',
      marketNotListed: 'The market is not currently listed by the comptroller',
      marketAlreadyListed: 'An admin tried to list the same market more than once',
      mathError: 'A math calculation error occurred',
      nonzeroBorrowBalance: 'The action cannot be performed since the account carries a borrow balance',
      priceError: 'The comptroller could not obtain a required price of an asset',
      rejection: 'The comptroller rejects the action requested by the market',
      snapshotError: 'The comptroller could not get the account borrows and exchange rate from the market',
      tooManyAssets: 'Attempted to enter more markets than are currently supported',
      tooMuchRepay: 'Attempted to repay more than is allowed by the protocol',
    },
    cTokenErrors: {
      generic: 'CToken Rejection',
      noError: 'Not a failure.',
      unauthorized: 'The sender is not authorized to perform this action.',
      badInput: 'An invalid argument was supplied by the caller.',
      comptrollerRejection: 'The action would violate the comptroller policy.',
      comptrollerCalculationError: 'An internal calculation has failed in the comptroller.',
      interestRateModelError: 'The interest rate model returned an invalid value.',
      invalidAccountPair: 'The specified combination of accounts is invalid.',
      invalidCloseAmountRequested: 'The amount to liquidate is invalid.',
      invalidCollateralFactor: 'The collateral factor is invalid.',
      mathError: 'A math calculation error occurred.',
      marketNotFresh: 'Interest has not been properly accrued.',
      marketNotListed: 'The market is not currently listed by its comptroller.',
      tokenInsufficientAllowance:
        'ERC-20 contract must allow Money Market contract to call transferFrom. The current allowance is either 0 or less than the requested supply, repayBorrow or liquidate amount.',
      tokenInsufficientBalance:
        'Caller does not have sufficient balance in the ERC-20 contract to complete the desired action.',
      tokenInsufficientCash:
        'The market does not have a sufficient cash balance to complete the transaction. You may attempt this transaction again later.',
      tokenTransferInFailed: 'Failure in ERC-20 when transfering token into the market.',
      tokenTransferOutFailed: 'Failure in ERC-20 when transfering token out of the market.',
    },
    actionModals: {
      supply: 'Supply',
      withdraw: 'Withdraw',
      approveInWallet: 'Confirm transaction in wallet',
      transactionPending: 'Transaction pending',
      supplyAPY: 'Supply APY',
      supplyRates: 'Supply Rates',
      distributionRate: 'Distribution APY',
      borrowAPR: 'Borrow APR',
      borrowRates: 'Borrow Rates',
      borrowLimit: 'Borrow Limit',
      borrowLimitUsedPercentage: 'Borrow Limit Used',
      balance: 'Balance',
      borrowBalance: 'Borrow Balance',
      approve: 'Approve',
      enableDescription: 'To Supply or Repay {{ token }} to the Deepr Protocol, you need to enable it first.',
      provideCollateral: 'To proceed with borrowing funds, you first need to provide collateral to secure your loan.',
      learnHowProvideCollateral: 'Learn how to provide collateral.',
      done: 'Done',
      enable: 'Enable {{ token }}',
      wrap: 'Wrap {{ token }}',
      unwrap: 'Unwrap {{ token }}',
      continueWithoutUnwrapping: 'Continue without unwrapping',
      max: 'MAX',
      total: 'Total',
      maxWrapped: 'The sum of your wIOTA and IOTA balance, minus 1 IOTA for gas fees',
      fullAmount: 'Full amount',
      limit: 'LIMIT',
      borrow: 'Borrow',
      repay: 'Repay',
      currentlySupplying: 'Currently Supplying',
      currentlyBorrowing: 'Currently Borrowing',
      validation: {
        amountRequired: 'Token amount is required',
        maxAmountExceeded: 'Token amount exceeds limit',
        minAmountNotReached: 'Token amount should be greater than 0',
        amountHigherThanLiquidity: 'The amount is higher than the market liquidity',
        amountHigherThanBorrowBalance: 'The amount is higher than the borrow balance',
        noFunds: 'No funds available',
      },
      collateral: {
        enable: 'Enable as Collateral',
        disable: 'Disable as Collateral',
        description:
          'Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation.',
        use: 'Use {{ token }} as collateral',
        remove: 'Remove {{ token }} as collateral',
      },
      steps: {
        wrap: {
          title: 'Wrapping {{ token }} required',
          description: 'Wrap your IOTA tokens to supply and earn rewards, and easily unwrap them when withdrawing.',
        },
        enable: {
          title: 'Enable token spend',
          description: 'Enable token spend for your {{ token }} tokens to start using them in the market.',
        },
        supply: {
          title: 'Confirm Supply transaction',
          description: 'Confirm the transaction to complete supplying your {{ token }} tokens and earn rewards.',
        },
        repay: {
          title: 'Confirm Repay transaction',
          description: 'Confirm the transaction to complete repaying your {{ token }} tokens.',
        },
      },
    },
    transactionStatus: {
      sign: {
        title: 'Confirm the transaction with Metamask',
      },
      broadcast: {
        title: 'Transaction broadcast',
      },
      captcha: {
        title: 'Validate captcha to continue',
      },
    },
    dismiss: 'Dismiss',
    pendingTransaction: 'Pending transaction',
    captcha: {
      header: 'Receive tokens',
      button: 'Get tokens',
    },
    fetchingError: 'There was an error fetching markets data.',
    networkError: 'Please change your network to {{ supportedNetwork }}',
    networkDown: {
      banner: 'There was a problem retrieving markets data.',
      bannerButton: 'Reload',
      description: 'There was an error fetching markets data',
    },
  },
  faucet: {
    intro: 'Welcome to',
    title: 'The Deepr Faucet',
    description: 'Connect your wallet and receive tokens',
    input: {
      label: 'Please enter a valid {{ network }} address',
      placeholder: 'Address',
      errors: {
        requiredAddress: 'Address is required',
        invalidAddress: 'Invalid address',
        walletNotConnected: 'Connect your wallet to continue',
      },
    },
    table: {
      token: 'Token',
      balance: 'Balance',
      value: 'Value',
    },
    buttons: {
      request: 'Drip tokens',
      addAsset: 'Add asset to wallet',
    },
  },
  liquidate: {
    title: 'Liquidate',
    tableHeading: {
      totalBorrowed: 'Total Borrowed',
      health: 'Health',
      borrowerAddress: 'Borrower Address',
      borrowMarkets: 'Borrow Markets',
    },
    form: {
      repayBorrow: 'Repay Borrow',
      borrowSelect: 'Select Borrow To Close',
      price: 'Price',
      userBorrowed: 'User Borrowed',
      maxQuantity: 'Max Quantity',
      amountPlaceholder: 'Amount to Close',
      desiredCollateral: 'Select Desired Collateral',
      receiveCollateral: 'Receive Collateral',
      bonusPrice: 'Bonus Price',
      userSupplied: 'User Supplied',
      youReceive: 'You Will Receive',
      liquidateButton: 'Liquidate',
      validation: {
        required: 'This field is required.',
      },
    },
    toast: {
      success: 'Liquidation successful',
      failure: 'Liquidation failed',
    },
  },
  terms: {
    title: 'Terms of Service',
    introduction: {
      title: '1. Introduction',
      body: 'These Terms of Service ("Terms") govern your access to and use of the services provided by Deepr.Finance ("we", "us", or "our"), a decentralized lending platform. By using our services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our services.',
    },
    elegibility: {
      title: '2. Elegibility',
      body: 'By using our services, you represent and warrant that you are at least 18 years old, have the legal capacity to enter into these Terms, and are not located in or a resident of any country, state, or other jurisdiction where access to or use of our platform is prohibited or restricted by applicable law or regulation. If you are using our services on behalf of an organization, you represent and warrant that you are authorized to bind the organization to these Terms. \n\nYou acknowledge and agree that it is your sole responsibility to ensure compliance with all laws, rules, and regulations in your jurisdiction, including but not limited to, any restrictions on the use of cryptocurrencies and decentralized finance platforms. We reserve the right to restrict or deny access to our services to users from certain jurisdictions at our sole discretion.',
    },
    platformUsage: {
      title: '3. Platform Usage',
      body: 'You acknowledge that our platform is built on blockchain technology, and as such, you are responsible for all costs and fees associated with your use of the platform, including but not limited to gas fees and network fees. You also acknowledge that we have no control over the underlying blockchain network and cannot guarantee the availability, functionality, or security of our services.',
    },
    promotions: {
      title: '4. Promotions, Airdrops, and NFTs',
      body: 'We may, from time to time, offer promotions, airdrops, or distribute non-fungible tokens (NFTs) to users of our platform. These promotions, airdrops, and NFTs are subject to the following terms:\n\na. Eligibility: We reserve the right to determine eligibility for any promotions, airdrops, or NFTs at our sole discretion. Participation may be limited to specific users or require the completion of certain actions, such as holding a minimum amount of tokens.\n\nb. Disclaimers: We are not responsible for any loss, damage, or harm resulting from your participation in promotions, airdrops, or NFT distributions. You acknowledge that the value of tokens and NFTs may be volatile and can change rapidly.\n\nc. Taxes: You are solely responsible for any taxes related to your participation in promotions, airdrops, or NFT distributions.',
    },
    risksAndDisclaimers: {
      title: '5. Risks and Disclaimers',
      body: 'You acknowledge and agree that using our platform involves various risks, including but not limited to, the following:\n\na. Technical Risks: You understand that blockchain technology is inherently experimental and may be subject to bugs, vulnerabilities, and unexpected changes. You acknowledge that we are not responsible for any loss or damage resulting from such issues.\n\nb. Regulatory Risks: The legal and regulatory landscape surrounding decentralized finance is evolving rapidly. You agree to comply with all applicable laws and regulations, and you acknowledge that we are not responsible for any legal consequences arising from your use of our services.\n\nc. Financial Risks: You acknowledge that using our platform involves financial risk, including but not limited to market volatility, liquidity risks, and the risk of losing all or part of your assets. You agree to use our services at your own risk and assume full responsibility for any losses or damages that may result.',
    },
    limitationOfLiability: {
      title: '6. Limitation of Liability',
      body: 'To the maximum extent permitted by applicable law, we and our affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, resulting from your use of our services.',
    },
    indemnification: {
      title: '7. Indemnification',
      body: "You agree to indemnify, defend, and hold harmless Deepr.Finance, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of our services or your breach of these Terms.",
    },
    governingLaw: {
      title: '8. Governing Law and Dispute Resolution',
      body: 'These Terms shall be governed by and construed in accordance with the laws of the British Virgin Islands, without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms or your use of our services shall be resolved through negotiation, mediation, or arbitration as agreed by the parties.',
    },
    amendments: {
      title: '9. Amendments and Updates',
      body: 'We reserve the right to amend or update these Terms at any time and at our sole discretion. We will notify you of any material changes by posting the updated Terms on our platform or by other means as deemed appropriate. Your continued use of our services after the effective date of any changes constitutes your acceptance of the revised Terms.',
    },
    severability: {
      title: '10. Severability',
      body: 'If any provision of these Terms is held to be invalid, illegal, or unenforceable by a court of competent jurisdiction, the remaining provisions shall remain in full force and effect, and the invalid, illegal, or unenforceable provision shall be deemed replaced by a valid, legal, and enforceable provision that most closely reflects the original intent of the parties.',
    },
    noWaiver: {
      title: '11. No Waiver',
      body: 'Our failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision, and no waiver of any term shall be deemed a further or continuing waiver of such term or any other term.',
    },
    entireAgreement: {
      title: '12. Entire Agreement',
      body: 'These Terms, together with any other policies or agreements referenced herein, constitute the entire agreement between you and Deepr.Finance concerning your use of our services and supersede all prior or contemporaneous understandings, agreements, or communications, whether written or oral, regarding the subject matter herein.',
    },
    contactInformation: {
      title: '13. Contact Information',
      body: 'If you have any questions, concerns, or comments about these Terms or our services, please contact us at contact@deepr.finance.',
    },
  },
  toast: {
    dashboard: {
      success: {
        title: 'Success!',
        description: 'Your transaction was successful.',
        addCToken: 'Add cToken to wallet',
      },
      failed: {
        title: 'Failed.',
        description: 'Your transaction failed.',
        networkError: 'Network error.',
      },
    },
    faucet: {
      success: {
        title: 'Done!',
        description: 'You have received your tokens',
      },
      ipMissing: {
        title: 'Error',
        description: "User IP couldn't be detected",
      },
      dailyQuotaReached: {
        title: 'Error',
        description: 'Daily quota reached',
      },
      dailyQuotaReachedLocktime: {
        description: 'Daily quota reached (Locktime)',
      },
      walletNotConnected: {
        title: 'Error',
        description: 'Please connect your wallet to continue',
      },
      networkNotSupported: {
        title: 'Error',
        description: 'Network not supported',
      },
      captchaNotValid: {
        title: 'Error',
        description: 'Captcha not valid',
      },
      generalError: {
        title: 'Error',
        description: 'Something went wrong',
      },
    },
  },
  subie: {
    hero: {
      title: 'Mint Your Subie NFT',
      titleMobile: 'Mint Subie NFT',
      description:
        'The Deepr Finance Subie NFTs hold significance beyond being mere collectibles. \nThey serve as your gateway to venture into uncharted DeFi realms.',
      learnMore: 'Learn More',
    },
    mint: {
      titleSingle: 'You are eligible to mint a Subie',
      titleMultiple: 'You are eligible to mint {{ amount }} Subies',
      amountInput: 'Set how many NFTs you wish to mint',
      mintButtonSingle: 'Mint a Subie',
      mintButtonMultiple: 'Mint {{ amount }} Subies',
      mintInProgress: 'Mint in progress',
      helloTo: 'Say Hello to Subie #{{ subieId }}',
      viewOnExplorer: 'View on Explorer',
      done: 'Done',
      dontMissTitle: 'Don’t miss the next opportunity',
      dontMissDescription: 'For the latest drops and special offers, make sure to follow us on socials',
      followOnX: 'Follow on X',
      joinDiscord: 'Join Discord',
      notEligibleTitle: 'You are not eligible to mint a Subie',
      notEligibleDescription:
        'To participate in the Subie NFT mint, you must have a special token in your wallet. Please ensure you have the necessary token and try again.',
      alreadyMintedTitle: 'All NFTs successfully minted',
      alreadyMintedDescription:
        "You've utilized all your mint tokens and successfully minted your NFTs. Thank you for your participation! Check your collection to view your unique pieces.",
      viewMySubies: 'View my Subies',
    },
  },
  staking: {
    title: 'Unlock passive income by staking DEEPR',
    learnMoreLink: 'Learn more about staking',
    learnMoreLinkMobile: 'Learn more',
    stake: 'Stake DEEPR',
    claim: 'Claim rewards',
    withdraw: 'Withdraw DEEPR',
    manage: 'Manage',
    stakingAPR: 'Staking APR',
    availableStake: 'Available to stake',
    yourStake: 'Your stake',
    rewards: 'Staking rewards',
    potentialDailyRewards: 'Potential daily rewards',
    potentialDailyRewardsTooltip:
      'Potential daily rewards are estimated based on staking your entire available balance',
    coolingPeriodEndsIn: 'Cooling period ends in',
    modal: {
      header: 'Stake DEEPR',
      stake: 'Stake',
      withdraw: 'Withdraw',
      stakeForm: {
        enable: 'Enable DEEPR',
        rightButtonText: 'MAX',
        balance: 'Wallet Balance',
        yourPoolShare: 'Your pool share',
        poolShare: 'Pool share',
        yourStake: 'Your total stake',
        totalStake: 'Total stake',
        validation: {
          maxAmountExceeded: 'Token amount exceeds balance',
        },
      },
      withdrawForm: {
        button: 'Initiate withdrawal & claim rewards',
      },
      disclaimer: {
        title: 'Withdrawal requires a 7-day cooling period',
        description:
          "During this period you won't receive staking rewards and you can't edit your staking position. The 7-day cooling period can't be reversed or cancelled.",
        unclaimedRewardsDescription:
          'Please note that initiating withdrawal automatically claims all your unclaimed staking rewards.',
      },
    },
  },
  common: {
    day: 'day',
    days: 'days',
    hour: 'hour',
    hours: 'hours',
    minute: 'minute',
    minutes: 'minutes',
    seconds: 'seconds',
    and: 'and',
  },
  bridge: {
    title: '$DEEPR Bridge',
    subtitle: 'Transfer your $DEEPR tokens between Shimmer EVM and IOTA EVM',
    form: {
      toNetwork: 'To',
      fromNetwork: 'From',
      receive: 'You will receive',
      amount: 'Amount',
      fee: 'Bridge transaction fee',
      destination: 'Destination Network',
      estimatedTime: 'Estimated time',
      timeOfArrival: '7 minutes',
      max: 'MAX',
      balance: 'Wallet Balance',
      validation: {
        maxAmountExceeded: 'Insufficient balance',
        invalidDestinationAddress: 'Invalid address',
        insufficientNativeToken: 'Insufficient native balance',
      },
      exchangeTooltip: 'Switch the direction of the operation.',
      useDifferentAddress: 'Use a different destination address',
      differentAddressTooltip:
        'Set a custom destination address. Be sure to double-check carefully, as sending to the wrong address could result in loss of funds.',
      destinationAddressPlaceholder: 'Enter EVM address...',
      buttons: {
        enable: 'Enable DEEPR',
        bridge: 'Bridge DEEPR',
      },
      toast: {
        success: {
          enable: {
            title: 'Token spend enabled',
            description: 'You can now bridge your tokens',
          },
          bridge: {
            title: 'Success',
            description: 'You successfully bridged your assets',
            actionLabel: 'Track on LayerZero Scan',
          },
        },
        pending: {
          bridge: {
            title: 'Confirming',
            description: 'The transaction has been submitted and is being processed.',
            actionLabel: 'Track on LayerZero Scan',
          },
        },
        error: {
          enable: {
            title: 'Token spend failed',
          },
          bridge: {
            title: 'Failure',
            descriptionInsufficientNativeTokens: `You don't have enough native tokens to pay for the transaction fees + gas costs.`,
          },
        },
      },
    },
  },
}
