import { Address, formatUnits, getContract, Hash } from 'viem'
import { useAccount, usePublicClient, useWalletClient } from 'wagmi'

import { SAFE_WALLET_DEFAULT_TX_OPTIONS } from '~/constants'
import { CustomError, TransactionName } from '~/helpers'
import cERC20 from '~/lib/abis/cERC20'
import { useTranslation } from '~/lib/i18n'

/**
 * CToken Error Codes Mapper
 * [ErrorCode]: i18n key
 */

export const cTokenErrorCodes: { [key: string]: string } = {
  0: 'cTokenErrors.noError',
  1: 'cTokenErrors.unauthorized',
  2: 'cTokenErrors.badInput',
  3: 'cTokenErrors.comptrollerRejection',
  4: 'cTokenErrors.comptrollerCalculationError',
  5: 'cTokenErrors.interestRateModelError',
  6: 'cTokenErrors.invalidAccountPair',
  7: 'cTokenErrors.invalidCloseAmountRequested',
  8: 'cTokenErrors.invalidCollateralFactor',
  9: 'cTokenErrors.mathError',
  10: 'cTokenErrors.marketNotFresh',
  11: 'cTokenErrors.marketNotListed',
  12: 'cTokenErrors.tokenInsufficientAllowance',
  13: 'cTokenErrors.tokenInsufficientBalance',
  14: 'cTokenErrors.tokenInsufficientCash',
  15: 'cTokenErrors.tokenTransferInFailed',
  16: 'cTokenErrors.tokenTransferOutFailed',
}

/**
 * useCTokenContractWithSigner Hook
 */

export function useCTokenContractWithSigner(cTokenAddress: Address, isNativeToken?: boolean) {
  const { t } = useTranslation('dashboard')
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient()
  const { address } = useAccount()

  if (!publicClient || !walletClient) {
    return {}
  }

  const cTokenContract = getContract({
    address: cTokenAddress,
    abi: cERC20,
    client: {
      public: publicClient,
      wallet: walletClient,
    },
  })

  const sendCTokenContractTransaction = async (operation: TransactionName, params: bigint) => {
    const simulationResult = (await cTokenContract.simulate[operation]([params], { account: address })).result
    const responseCode = +formatUnits(simulationResult, 0)

    if (responseCode != 0) {
      throw new CustomError(t(cTokenErrorCodes[responseCode]) || t('cTokenErrors.generic'), responseCode)
    }

    return cTokenContract.write[operation]([params], SAFE_WALLET_DEFAULT_TX_OPTIONS)
  }

  let borrow: (value: bigint) => Promise<Hash>
  let redeem: (value: bigint) => Promise<Hash>
  let redeemUnderlying: (value: bigint) => Promise<Hash>
  let mint: (value: bigint) => Promise<Hash>
  let repayBorrow: (value: bigint) => Promise<Hash>

  if (isNativeToken) {
    // TODO: This is done because we cannot call a native token contract function using simulation
    const cNativeTokenContract = getContract({
      address: cTokenAddress,
      abi: cERC20,
      client: {
        client: publicClient,
        wallet: walletClient,
      },
    })
    mint = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.mint([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    repayBorrow = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.repayBorrow([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    borrow = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.borrow([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    redeem = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.redeem([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
    redeemUnderlying = (value) => {
      // Does not handle errors since it reverts upon any failures
      return cNativeTokenContract.write.redeemUnderlying([value], SAFE_WALLET_DEFAULT_TX_OPTIONS)
    }
  } else {
    mint = async (value) => sendCTokenContractTransaction('mint', value)
    repayBorrow = async (value) => sendCTokenContractTransaction('repayBorrow', value)
    borrow = async (value) => sendCTokenContractTransaction('borrow', value)
    redeem = async (value) => sendCTokenContractTransaction('redeem', value)
    redeemUnderlying = async (value) => sendCTokenContractTransaction('redeemUnderlying', value)
  }

  return { mint, borrow, redeem, redeemUnderlying, repayBorrow }
}
