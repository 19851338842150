import { useAccount, usePublicClient } from 'wagmi'

import { useComptrollerContractWithSigner } from '~/hooks/useComptrollerContractWithSigner'
import { useDistributionToken } from '~/hooks/useDistributionToken'
import { TransactionStatus, useTransactionStatus } from '~/hooks/useTransactionStatus'
import * as Analytics from '~/lib/analytics'

/**
 * UseClaimModal Hook
 */

export function useClaimModal() {
  const { distributionToken, refetch } = useDistributionToken()
  const { transactionStatus, setTransactionStatus, closeAllToasts } = useTransactionStatus()
  const { claimDeepr } = useComptrollerContractWithSigner()

  const { address } = useAccount()
  const publicClient = usePublicClient()

  const isPending = transactionStatus === TransactionStatus.SIGN
  const isBroadcasting = transactionStatus === TransactionStatus.BROADCAST

  const claim = async () => {
    Analytics.addBreadcrumb(Analytics.BreadcrumbsTypes.CLAIM_ATTEMPTED)

    setTransactionStatus(null)
    closeAllToasts()

    if (!address) {
      return false
    }

    try {
      if (!claimDeepr) {
        throw new Error('Missing signer')
      }

      setTransactionStatus(TransactionStatus.SIGN)

      const hash = await claimDeepr(address)

      setTransactionStatus(TransactionStatus.BROADCAST)

      await publicClient?.waitForTransactionReceipt({ hash })

      setTransactionStatus(TransactionStatus.SUCCESS)
      refetch()

      return true
    } catch (e) {
      setTimeout(() => setTransactionStatus(TransactionStatus.FAILED, e), 300)
      Analytics.trackException(e)

      return false
    }
  }

  return {
    claim,
    isPending,
    isBroadcasting,
    distributionToken,
  }
}
