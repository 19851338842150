import { Network } from '~/lib/networks'

export const networkIcons: Record<number, Record<string, string>> = {
  [Network.Shimmer]: { solid: '/assets/networks/shimmer.png', transparent: '/assets/networks/shimmer_transparent.png' },
  [Network.Shimmer_Testnet]: {
    solid: '/assets/networks/shimmer.png',
    transparent: '/assets/networks/shimmer_transparent.png',
  },
  [Network.IOTA_Testnet]: { solid: '/assets/networks/iota.png', transparent: '/assets/networks/iota_transparent.png' },
  [Network.IOTA]: { solid: '/assets/networks/iota.png', transparent: '/assets/networks/iota_transparent.png' },
}
