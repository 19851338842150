import { KeyboardEvent } from 'react'
import DecimalJS from 'decimal.js'
import { compact, Dictionary, find, forEach, includes, keyBy, sumBy } from 'lodash'
import { Address, formatUnits, parseUnits } from 'viem'

import { BORROW_LIMIT_CAP, DEFAULT_DECIMALS } from '~/constants'
import { tokens } from '~/constants/tokens'
import { calculateApy, isNumber } from '~/helpers'
import { MarketActions, MarketsDisableStatuses } from '~/types'
import { AccountLimits, CTokenBalancesAll, CTokenMetadataAll } from '~/types/contracts'

type BalanceMapItem = {
  supplyUnderlyingBalance: number
  supplyUnderlyingBalanceFIAT: number
  borrowUnderlyingBalance: number
  borrowUnderlyingBalanceFIAT: number
  underlyingPriceFIAT: number
  walletTokenBalance: number
  supplyApy: number
  distributionSupplyApy: number
  borrowApy: number
  distributionBorrowApy: number
  underlyingTokenAllowance: number
  weightedAveragedApy: number
  ctokenBalance: bigint
}

type CTokenUnderlyingPriceMapType = Dictionary<{ cToken: Address; underlyingPrice: number }>
type BalancesMapType = Dictionary<BalanceMapItem>

const LOW_RISK_BAR_COLOR = 'linear-gradient(180deg, #56E39F 0%, #56E3B6 100%)'
const MEDIUM_RISK_BAR_COLOR = 'linear-gradient(180deg, #F3C969 0%, #F3E169 100%)'
const HIGH_RISK_BAR_COLOR = 'linear-gradient(180deg, #E84855 0%, #E84848 100%)'
const MEDIUM_RISK_LIMIT = 50
const HIGH_RISK_LIMIT = 80

const bigIntToNumber = (value: bigint) => +formatUnits(value, 0)

export function getEnrichedMarkets(
  allMarkets: Address[],
  cTokenMetadataAll: CTokenMetadataAll,
  balancesMap: BalancesMapType,
  currentChainId: number,
  totalBorrowed: number,
  totalBorrowLimit: number,
  accountLimits?: AccountLimits,
  disabledMarkets?: MarketsDisableStatuses,
  supplyGasFeeUnits?: number
) {
  const cTokenMetadataMap = keyBy(cTokenMetadataAll, 'cToken')

  const enrichedMarkets = allMarkets?.map((cToken) => {
    const {
      isListed,
      underlyingAssetAddress,
      collateralFactorMantissa,
      totalCash,
      underlyingDecimals: rawUnderlyingDecimals,
    } = cTokenMetadataMap[cToken]

    const {
      supplyUnderlyingBalance,
      supplyUnderlyingBalanceFIAT,
      borrowUnderlyingBalance,
      borrowUnderlyingBalanceFIAT,
      underlyingPriceFIAT,
      walletTokenBalance,
      supplyApy,
      borrowApy,
      distributionSupplyApy,
      distributionBorrowApy,
      underlyingTokenAllowance,
      ctokenBalance,
    } = balancesMap[cToken]

    const currentNetworkTokensMap = tokens[currentChainId]
    const tokenStaticData = currentNetworkTokensMap[cToken]

    if (!tokenStaticData || !isListed) {
      return null
    }

    const isMember = includes(accountLimits?.markets, cToken)
    const underlyingDecimals = bigIntToNumber(rawUnderlyingDecimals)
    const collateralRate = +formatUnits(collateralFactorMantissa, DEFAULT_DECIMALS)

    const marketDisabledActions = disabledMarkets?.[tokenStaticData.underlyingSymbol] ?? []

    const wallet = getWalletBalance(walletTokenBalance, tokenStaticData.shouldUseBufferForGas, supplyGasFeeUnits)

    return {
      cToken,
      underlyingToken: underlyingAssetAddress,
      underlyingSymbol: tokenStaticData.underlyingSymbol,
      underlyingDecimals,
      underlyingPriceFIAT,
      collateralRate,
      wallet,
      cTokenBalance: ctokenBalance,
      isNativeToken: !!tokenStaticData?.isNativeToken,
      isWrappedNativeToken: !!tokenStaticData?.isWrappedNativeToken,
      supply: {
        apy: supplyApy,
        distributionApy: distributionSupplyApy,
        balance: supplyUnderlyingBalance,
        balanceInFiat: supplyUnderlyingBalanceFIAT,
        isCollateral: collateralRate === 0 ? null : isMember,
        isSupplying: supplyUnderlyingBalance > 0,
        underlyingTokenAllowance,
        withdrawMaxAmount: Math.min(
          (totalBorrowLimit - totalBorrowed) / collateralRate / underlyingPriceFIAT,
          supplyUnderlyingBalance
        ),
        withdrawCappedAmount: Math.max(
          (totalBorrowLimit - totalBorrowed / BORROW_LIMIT_CAP) / collateralRate / underlyingPriceFIAT,
          0
        ),
        isDisabled: marketDisabledActions?.includes(MarketActions.SUPPLY),
      },
      borrow: {
        apy: borrowApy,
        distributionApy: distributionBorrowApy,
        balance: borrowUnderlyingBalance,
        balanceInFiat: borrowUnderlyingBalanceFIAT,
        isBorrowing: borrowUnderlyingBalance > 0,
        liquidity: +formatUnits(totalCash, underlyingDecimals) * underlyingPriceFIAT,
        // TODO: totalBorrowLimit possibly 0.
        borrowLimitUtilization: getBorrowLimitUtilization(totalBorrowLimit, borrowUnderlyingBalanceFIAT),
        borrowMaxAmount: (totalBorrowLimit - totalBorrowed) / underlyingPriceFIAT,
        borrowAmountCapped: Math.max(0, (totalBorrowLimit * BORROW_LIMIT_CAP - totalBorrowed) / underlyingPriceFIAT),
        isDisabled: marketDisabledActions?.includes(MarketActions.BORROW),
      },
      repay: {
        isDisabled: marketDisabledActions?.includes(MarketActions.REPAY),
      },
      withdraw: {
        isDisabled: marketDisabledActions?.includes(MarketActions.WITHDRAW),
      },
    }
  })

  return compact(enrichedMarkets)
}

export function getBalancesMap(
  cTokenMetadataAll: CTokenMetadataAll,
  cTokenUnderlyingPriceMap: CTokenUnderlyingPriceMapType,
  currentChainId: number,
  cTokenBalancesAll?: CTokenBalancesAll
) {
  const cTokenBalancesMap = keyBy(cTokenBalancesAll, 'cToken')
  const balancesMap: Record<string, BalanceMapItem> = {}

  forEach(
    cTokenMetadataAll,
    ({
      cToken,
      supplyRatePerSecond,
      borrowRatePerSecond,
      underlyingDecimals: rawUnderlyingDecimals,
      compSupplySpeed,
      compBorrowSpeed,
      totalSupply: totalSupplyResult,
      totalBorrows,
      cTokenDecimals,
      exchangeRateCurrent,
    }) => {
      const underlyingPriceFIAT = cTokenUnderlyingPriceMap[cToken]?.underlyingPrice
      const { balanceOfUnderlying, borrowBalanceCurrent, tokenBalance, tokenAllowance, balanceOf } = cTokenBalancesMap[
        cToken
      ] || {
        balanceOfUnderlying: BigInt(0),
        borrowBalanceCurrent: BigInt(0),
        tokenBalance: BigInt(0),
        balanceOf: BigInt(0),
      }

      const underlyingDecimals = bigIntToNumber(rawUnderlyingDecimals)
      const supplyUnderlyingBalance = +formatUnits(balanceOfUnderlying, underlyingDecimals)
      const borrowUnderlyingBalance = +formatUnits(borrowBalanceCurrent, underlyingDecimals)
      const walletTokenBalance = +formatUnits(tokenBalance, underlyingDecimals)

      const supplyUnderlyingBalanceFIAT = supplyUnderlyingBalance * underlyingPriceFIAT
      const borrowUnderlyingBalanceFIAT = borrowUnderlyingBalance * underlyingPriceFIAT

      const distributionToken = find(tokens[currentChainId], { isDistributionToken: true })

      let compSupplySpeedUSD = 0
      let compBorrowSpeedUSD = 0

      if (distributionToken) {
        const distributionUnderlyingPrice = cTokenUnderlyingPriceMap[distributionToken.address]?.underlyingPrice
        compSupplySpeedUSD = +formatUnits(compSupplySpeed, DEFAULT_DECIMALS) * distributionUnderlyingPrice
        compBorrowSpeedUSD = +formatUnits(compBorrowSpeed, DEFAULT_DECIMALS) * distributionUnderlyingPrice
      }

      const mantissa = 18 + underlyingDecimals - bigIntToNumber(cTokenDecimals)
      const oneCTokenInUnderlying = +formatUnits(exchangeRateCurrent, mantissa)
      const totalSupplyScaled = +formatUnits(totalSupplyResult, bigIntToNumber(cTokenDecimals))

      const totalSupplyFiat = totalSupplyScaled * oneCTokenInUnderlying * underlyingPriceFIAT
      const totalBorrowsFiat = +formatUnits(totalBorrows, underlyingDecimals) * underlyingPriceFIAT

      const supplyApy = calculateApy(+formatUnits(supplyRatePerSecond, DEFAULT_DECIMALS))
      const borrowApy = calculateApy(+formatUnits(borrowRatePerSecond, DEFAULT_DECIMALS))

      const distributionSupplyApy = calculateApy(compSupplySpeedUSD / totalSupplyFiat)
      const distributionBorrowApy = calculateApy(compBorrowSpeedUSD / totalBorrowsFiat)

      const weightedAveragedApy =
        (supplyApy + distributionSupplyApy) * supplyUnderlyingBalanceFIAT +
        (distributionBorrowApy - borrowApy) * borrowUnderlyingBalanceFIAT

      balancesMap[cToken] = {
        supplyUnderlyingBalance,
        supplyUnderlyingBalanceFIAT,
        borrowUnderlyingBalance,
        borrowUnderlyingBalanceFIAT,
        walletTokenBalance,
        underlyingPriceFIAT,
        supplyApy,
        distributionSupplyApy,
        borrowApy,
        distributionBorrowApy,
        weightedAveragedApy,
        underlyingTokenAllowance: +formatUnits(tokenAllowance || BigInt(0), underlyingDecimals),
        ctokenBalance: balanceOf,
      }
    }
  )

  return balancesMap
}

export function getLighthouseData(balancesMap: BalancesMapType, accountLiquidity: number) {
  const balancesArray = Object.values(balancesMap)
  const supplyBalance = sumBy(balancesArray, (balance) =>
    isNumber(balance.supplyUnderlyingBalanceFIAT) ? balance.supplyUnderlyingBalanceFIAT : 0
  )
  const borrowBalance = sumBy(balancesArray, (balance) =>
    isNumber(balance.borrowUnderlyingBalanceFIAT) ? balance.borrowUnderlyingBalanceFIAT : 0
  )
  const netApy =
    sumBy(balancesArray, (balance) => (isNumber(balance.weightedAveragedApy) ? balance.weightedAveragedApy : 0)) /
      supplyBalance || 0

  const borrowLimit = accountLiquidity + borrowBalance

  return {
    supplyBalance,
    borrowBalance,
    netApy,
    borrowLimit,
    borrowLimitUtilization: getBorrowLimitUtilization(borrowLimit, borrowBalance),
  }
}

export async function waitForToastClose() {
  return new Promise((r) => setTimeout(r, 0))
}

export function formatInputValue(rawAmount: string | number, underlyingDecimals: number) {
  const amount = new DecimalJS(rawAmount)

  return parseUnits(amount.toFixed(underlyingDecimals), underlyingDecimals)
}

export const getLimitBarColor = (value: number) => {
  if (value < MEDIUM_RISK_LIMIT) return LOW_RISK_BAR_COLOR
  if (value >= HIGH_RISK_LIMIT) return HIGH_RISK_BAR_COLOR

  return MEDIUM_RISK_BAR_COLOR
}

export const getKeyPressHandlerLimitingDecimals = (decimalsLimit: number, amount: number) => {
  let currentValue = amount?.toString()
  const valueDecimals = currentValue?.split('.')?.[1]

  return (e: KeyboardEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value

    const hasReachedDecimalsLimit = valueDecimals?.length >= decimalsLimit
    const changedIndex = [...newValue].findIndex((char, index) => char !== currentValue?.[index])
    const isEnteringDecimal = changedIndex > newValue.indexOf('.') && newValue.length > currentValue?.length

    if (hasReachedDecimalsLimit && isEnteringDecimal) {
      e.currentTarget.value = currentValue
    } else {
      currentValue = newValue
    }
  }
}

export const toFixedFloor = (value: number | string, decimalsLimit: number) => {
  const number = typeof value === 'number' ? value : Number(value)

  const decimals = `${number}`.split('.')?.[1]

  if (!decimalsLimit || decimals?.length <= decimalsLimit) {
    return number.toString()
  }

  const expDecimal = Math.pow(10, decimalsLimit)

  return (Math.floor(number * expDecimal) / expDecimal).toFixed(decimalsLimit)
}

const getBorrowLimitUtilization = (limit: number, balance: number) => (limit ? balance / limit : 0)

const getWalletBalance = (balance: number, shouldUseBufferForGas?: boolean, supplyGasFeeUnits?: number) => {
  if (shouldUseBufferForGas && supplyGasFeeUnits) {
    const hasBalance = balance > 0
    if (!hasBalance) {
      return 0
    }

    const hasBalanceForTx = balance > supplyGasFeeUnits
    if (!hasBalanceForTx) {
      return 0.01
    }

    return balance - supplyGasFeeUnits
  }

  return balance
}
