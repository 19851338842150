import { useEffect, useMemo } from 'react'
import { ToastPosition, useBreakpointValue, useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import Toast, { ToastType } from '~/components/dashboard/Toast'

export const TOAST_DURATION = 10000

const useCommonToastOptions = () => {
  const isMobileViewport = useBreakpointValue({ base: true, lg: false })

  return useMemo(
    () => ({
      isClosable: true,
      position: (isMobileViewport ? 'bottom' : 'top') as ToastPosition,
      containerStyle: {
        marginTop: isMobileViewport ? '0px' : '16px',
        paddingLeft: isMobileViewport ? '8px' : '0px',
        paddingRight: isMobileViewport ? '8px' : '0px',
        maxWidth: '1340px', // chakra container.lg + 60px
        width: '100%',
        display: 'flex',
        justifyContent: isMobileViewport ? 'center' : 'flex-end',
      },
    }),
    [isMobileViewport]
  )
}

interface ShowToastProps {
  variant: ToastType
  title: string
  description?: string
  duration?: number
  onActionClick?: (onClose: () => void) => void
  onCloseComplete?: () => void
  actionLabel?: string
}

export const useToastMessage = () => {
  const toast = useToast()
  const commonToastOptions = useCommonToastOptions()

  const { pathname } = useRouter()

  useEffect(() => {
    toast.closeAll()
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const showToast = ({
    variant,
    title,
    description,
    onActionClick,
    onCloseComplete,
    duration = TOAST_DURATION,
    actionLabel,
  }: ShowToastProps) =>
    toast({
      render: ({ onClose }) => (
        <Toast
          title={title}
          description={description}
          variant={variant}
          onClose={onClose}
          onActionClick={onActionClick ? () => onActionClick(onClose) : undefined}
          actionLabel={actionLabel}
        />
      ),
      status: variant === ToastType.SUCCESS ? ToastType.SUCCESS : 'error',
      duration,
      onCloseComplete,
      ...commonToastOptions,
    })

  return { showToast, closeAllToasts: toast.closeAll, close: toast.close }
}

export default useCommonToastOptions
