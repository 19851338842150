import { Box, Image, useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'

import Footer from '~/components/layout/Footer'
import Header from '~/components/layout/Header'
import NetworkWarningBanner from '~/components/layout/NetworkBanner'
import { LAYOUT_CALC } from '~/constants'
import { SUPPORTED_NETWORKS } from '~/constants/tokens'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'
import ParallaxParticles from './ParallaxParticles'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { chainId } = useAccount()
  const { pathname } = useRouter()
  const { isConnected } = useHydratedAccount()

  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

  const isDashboard = pathname === '/dashboard'
  const isStaking = pathname === '/staking'
  const isBridge = pathname === '/bridge'
  const isHome = pathname === '/'
  const isFAQ = pathname === '/faq'
  const isSubieMint = pathname === '/subies'
  const isPageWithNetworkBanner = isDashboard || isStaking || isBridge || isSubieMint

  const showNetworkWarningBanner =
    isPageWithNetworkBanner && isConnected && chainId && !SUPPORTED_NETWORKS.includes(chainId) && !isOpenDrawer

  return (
    <Box pos="relative" overflow="hidden">
      {!isFAQ && <ParallaxParticles />}
      {!isHome && (
        <Box minH="100vh" position="absolute" top={0} right={0} left={0} bottom={0}>
          <Box
            position="absolute"
            top={0}
            right={0}
            left={0}
            bottom={0}
            background="radial-gradient(55.84% 44% at 50% 0%, #081C49 0%, #030A1A 100%), #030A1A;"
          />
          <ParallaxParticles />
          <Box
            position="absolute"
            top={0}
            right={0}
            left={0}
            bgSize="cover"
            maxW="100vw"
            height="100vh"
            bgImage="url('/assets/lighting.png')"
          />
        </Box>
      )}
      {showNetworkWarningBanner && <NetworkWarningBanner />}
      <Header isOpenDrawer={isOpenDrawer} onOpenDrawer={onOpenDrawer} onCloseDrawer={onCloseDrawer} />

      {isHome ? (
        children
      ) : (
        <Box minH={LAYOUT_CALC} position="relative">
          {children}
        </Box>
      )}
      <Footer />

      {isSubieMint && !isConnected && (
        <>
          <Image
            srcSet="/assets/subies/subie-lg.png, /assets/subies/subie-lg@2x.png 2x, /assets/subies/subie-lg@3x.png 3x"
            pos="absolute"
            w={{ base: '240px', md: '500px', xl: 'initial' }}
            right={{ base: '-10%', md: -40, xl: -52, '2xl': 0 }}
            bottom={{ base: '27%', md: 12, xl: 24 }}
            zIndex={0}
          />
          <Image
            srcSet="/assets/subies/subie-md.png, /assets/subies/subie-md@2x.png 2x, /assets/subies/subie-md@3x.png 3x"
            w={{ base: '192px', xl: 'initial' }}
            pos="absolute"
            top={{ base: 6, md: 20, xl: -2 }}
            left={{ base: -20, md: 0, xl: -12 }}
            zIndex={0}
          />
          <Image
            display={{ base: 'none', xl: 'block' }}
            srcSet="/assets/subies/subie-sm.png, /assets/subies/subie-sm@2x.png 2x, /assets/subies/subie-sm@3x.png 3x"
            pos="absolute"
            left="22%"
            bottom="15%"
            zIndex={0}
          />
        </>
      )}
    </Box>
  )
}

export default Layout
