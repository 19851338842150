import { Box, Container, HStack, Text } from '@chakra-ui/react'
import { useAccount, useSwitchChain } from 'wagmi'

import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { DEFAULT_NETWORK_ID, Network, NetworksNames } from '~/lib/networks'
import { SmallButton } from './SmallButton'

interface Props {
  label?: string
  buttonLabel?: string
  onButtonClick?: () => void
}

const NetworkWarningBanner = ({ label, buttonLabel, onButtonClick }: Props) => {
  const { t } = useTranslation('layout')
  const { chainId } = useAccount()
  const { switchChain } = useSwitchChain()

  const defaultNetworkName = NetworksNames[DEFAULT_NETWORK_ID]

  const handleChangeNetworkButton = async () => {
    try {
      const provider = window.ethereum
      if (!provider) {
        return
      }

      switchChain({
        chainId: DEFAULT_NETWORK_ID,
      })
    } catch (e) {
      Analytics.trackException(e)
    }
  }

  return (
    <Box bgColor="background.highRisk" position="relative" py={4}>
      <Container maxW="container.xl">
        <HStack alignItems="center" justifyContent="center" spacing={4}>
          <Text fontSize="sm" fontWeight="600" suppressHydrationWarning>
            {label ||
              t('notConnectedToNetwork', {
                unsupportedNetwork: NetworksNames[chainId as Network] || t('yourNetwork'),
                supportedNetwork: defaultNetworkName,
              })}
          </Text>
          <Box>
            <SmallButton size="sm" onClick={onButtonClick || handleChangeNetworkButton}>
              {buttonLabel || t('switchNetwork')}
            </SmallButton>
          </Box>
        </HStack>
      </Container>
    </Box>
  )
}

export default NetworkWarningBanner
