import { FC } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConnectKitProvider } from 'connectkit'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { GoogleAnalytics, usePageViews } from 'nextjs-google-analytics'
import { WagmiProvider } from 'wagmi'

import { toastOptions } from '~/components/dashboard/Toast'
import Layout from '~/components/layout/Layout'
import { COLOR_MODE_LOCAL_STORAGE_KEY, WEB_URL } from '~/constants'
import { useTranslation } from '~/lib/i18n'
import { config } from '~/lib/web3'
import { connectKitTheme, theme } from '~/styles/theme'

import 'focus-visible/dist/focus-visible'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/800.css'
import '~/components/lighthouse/progress-circle.css'
import '~/components/home/carousel.css'

const IMAGE_PATH = 'assets/social-share.png'

const colorModeManager = createLocalStorageManager(COLOR_MODE_LOCAL_STORAGE_KEY)
const queryClient = new QueryClient()

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { t } = useTranslation('layout')
  usePageViews()

  const imageURL = new URL(IMAGE_PATH, WEB_URL).href

  return (
    <ChakraProvider colorModeManager={colorModeManager} resetCSS theme={theme} toastOptions={toastOptions}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <ConnectKitProvider
            options={{ initialChainId: 0, enforceSupportedChains: false }}
            mode="dark"
            customTheme={connectKitTheme}
          >
            <Head>
              <title>{t('meta.title')}</title>
              <meta name="description" content={t('meta.description')} />

              <meta property="og:image" content={imageURL} key="ogImage" />
              <meta property="twitter:image" content={imageURL} key="twitterCardImage" />

              <meta property="og:type" content="website" key="ogType" />
              <meta property="og:title" content={t('meta.title')} key="ogTitle" />
              <meta property="og:description" content={t('meta.description')} key="ogDescription" />

              <meta property="twitter:card" content="summary_large_image" key="twitterCardSummary" />
              <meta property="twitter:title" content={t('meta.title')} key="twitterCardTitle" />
              <meta property="twitter:description" content={t('meta.description')} key="twitterCardDescription" />
              <link rel="apple-touch-icon" sizes="76x76" href="/static/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/static/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/static/favicon-16x16.png" />
              {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#121212" /> */}
              <meta name="msapplication-TileImage" content="/static/ms-icon-144x144.png" />
              {/* <link rel="manifest" href="/site.webmanifest" /> */}
            </Head>
            <GoogleAnalytics />
            <ParallaxProvider>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </ParallaxProvider>
          </ConnectKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  )
}

export default App
