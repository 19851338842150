import { useEffect, useMemo, useState } from 'react'
import { filter, find, map } from 'lodash'
import useSWR from 'swr'
import { PublicClient } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

import { addresses } from '~/constants/addresses'
import { tokens } from '~/constants/tokens'
import { estimateGasFee } from '~/helpers'
import { getBalancesMap, getEnrichedMarkets, getLighthouseData } from '~/helpers/dashboard'
import { disabledFeatures } from '~/helpers/env'
import CompoundLens from '~/lib/abis/CompoundLens'
import { CTokenBalancesAll, CTokenMetadataAll } from '~/types/contracts'
import useSupportedNetworkId from './useSupportedNetworkId'
import { useUnderlyingPriceData } from './useUnderlyingPriceData'

const REFRESH_INTERVAL = 13000
const SUPPLY_GAS_FEE_ROUND_UP_MULTIPLIER = 1.25

const fetcher = async (chainId: number, publicClient: PublicClient, account?: string) => {
  const addressesByNetwork = addresses[chainId]
  const allMarkets = map(tokens[chainId], 'address')

  const [cTokenMetadataAll, cTokenBalancesAll] = await Promise.all([
    publicClient.simulateContract({
      abi: CompoundLens,
      address: addressesByNetwork.lens,
      functionName: 'cTokenMetadataAll',
      args: [allMarkets],
    }),
    account
      ? publicClient.simulateContract({
          abi: CompoundLens,
          address: addressesByNetwork.lens,
          functionName: 'cTokenBalancesAll',
          args: [allMarkets, account],
        })
      : undefined,
  ])

  return {
    cTokenMetadataAll: cTokenMetadataAll.result as CTokenMetadataAll,
    cTokenBalancesAll: cTokenBalancesAll?.result as CTokenBalancesAll | undefined,
    allMarkets,
  }
}

/**
 * useMarkets Hook
 */

export const useMarkets = () => {
  const { address } = useAccount()

  const currentChainId = useSupportedNetworkId()

  const publicClient = usePublicClient({ chainId: currentChainId })
  const { cTokenUnderlyingPriceMap, accountLiquidity, accountLimits } = useUnderlyingPriceData()

  const { data, mutate, error } = useSWR(
    `${currentChainId}${address || 'markets'}`,
    () => fetcher(currentChainId, publicClient as PublicClient, address),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

  const { cTokenMetadataAll, cTokenBalancesAll, allMarkets } = data || {}

  const balancesMap = useMemo(
    () =>
      cTokenMetadataAll
        ? getBalancesMap(cTokenMetadataAll, cTokenUnderlyingPriceMap, currentChainId, cTokenBalancesAll)
        : {},
    [cTokenBalancesAll, cTokenMetadataAll, cTokenUnderlyingPriceMap, currentChainId]
  )

  const summary = useMemo(() => getLighthouseData(balancesMap, accountLiquidity), [balancesMap, accountLiquidity])

  const disabledMarkets = disabledFeatures?.markets
  const isProtocolDisabled = disabledFeatures?.protocol?.disabled

  const [supplyGasFeeUnits, setSupplyGasFeeUnits] = useState<number | undefined>()

  const enrichedMarkets = useMemo(
    () =>
      allMarkets && cTokenMetadataAll
        ? getEnrichedMarkets(
            allMarkets,
            cTokenMetadataAll,
            balancesMap,
            currentChainId,
            summary.borrowBalance,
            summary.borrowLimit,
            accountLimits,
            disabledMarkets,
            supplyGasFeeUnits
          )
        : [],
    [
      accountLimits,
      allMarkets,
      balancesMap,
      cTokenMetadataAll,
      currentChainId,
      disabledMarkets,
      summary.borrowBalance,
      summary.borrowLimit,
      supplyGasFeeUnits,
    ]
  )

  useEffect(() => {
    const runGasFeeEstimation = async () => {
      const smrMarket = enrichedMarkets.find((market) => market.underlyingSymbol === 'SMR')

      if (!smrMarket) {
        return
      }

      const { gasFeeUnits } = await estimateGasFee('mint', smrMarket, publicClient, address)

      const newGasFeeUnits = gasFeeUnits * SUPPLY_GAS_FEE_ROUND_UP_MULTIPLIER

      if (newGasFeeUnits !== supplyGasFeeUnits) {
        setSupplyGasFeeUnits(newGasFeeUnits)
      }
    }

    if (address) {
      runGasFeeEstimation()
    }
  }, [enrichedMarkets, address, publicClient, supplyGasFeeUnits])

  // We filter the Distribution Token market (cDEEPR) for the release.
  // This market could be reinstated once the protocol is live.
  // Filtering is implemented at this stage to be able to return the rawMarkets object
  // with all the markets, necessary for the distributionToken price information
  // TODO: Review this market exclusion once the protocol is live
  const distributionToken = find(tokens[currentChainId], 'isDistributionToken')
  const filteredMarkets = filter(enrichedMarkets, (market) => market.cToken !== distributionToken?.address)

  const supplyingMarkets = useMemo(
    () => filteredMarkets?.filter((market) => !!market?.supply.isSupplying),
    [filteredMarkets]
  )
  const borrowingMarkets = useMemo(
    () => filteredMarkets?.filter((market) => !!market?.borrow.isBorrowing),
    [filteredMarkets]
  )
  const canBorrow = useMemo(
    () => filteredMarkets?.some((market) => market?.supply?.balance > 0 && market?.supply?.isCollateral),
    [filteredMarkets]
  )

  const isSupplying = !!supplyingMarkets?.length
  const isBorrowing = !!borrowingMarkets?.length
  const hasMarkets = !!filteredMarkets?.length

  return {
    summary,
    supplyingMarkets,
    borrowingMarkets,
    markets: filteredMarkets,
    rawMarkets: enrichedMarkets,
    isSupplying,
    isBorrowing,
    hasMarkets,
    canBorrow,
    refetch: mutate,
    isLoading: data === undefined && !error,
    error,
    isProtocolDisabled,
  }
}
