import { modalAnatomy } from '@chakra-ui/anatomy'
import { cssVar, extendTheme } from '@chakra-ui/react'
import { createMultiStyleConfigHelpers, defineStyleConfig } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys)

const $tooltipArrowBg = cssVar('popper-arrow-bg')

const customModalBaseStyle = definePartsStyle({
  overlay: {
    bg: 'radial-gradient(50% 50% at 50% 50%, rgba(6, 21, 55, 0.8) 0%, rgba(3, 10, 26, 0.8) 100%)',
    backdropFilter: 'blur(5px)',
  },
  dialog: {
    bg: 'linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)',
    backdropFilter: 'blur(16px)',
    boxShadow: 'none',
    border: '1px solid #FFFFFF16',
    borderRadius: '16px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #FFFFFF16',
  },
  closeButton: {
    top: '15px',
    right: '30px',
  },
})

const modalTheme = defineMultiStyleConfig({
  baseStyle: customModalBaseStyle,
})

const primaryTextColor = 'rgba(255, 255, 255, 0.92)'
const secondaryTextColor = 'rgba(255, 255, 255, 0.72)'
const tooltipBgColor = '#273350'

export const theme = extendTheme({
  fonts: {
    heading: 'Manrope, sans-serif',
    body: 'Manrope, sans-serif',
  },
  colors: {
    primary: '#6153CC',
    primaryGradient: 'linear-gradient(93.74deg, #6153CC 3.56%, #795EC1 97.35%)',
    secondary: '#56E39F',
    positiveMuted: 'rgba(134, 177, 156, 0.72)',
    negativeMuted: 'rgba(181, 123, 128, 0.72)',
    error: '#E84855',
    text: {
      primary: primaryTextColor,
      secondary: secondaryTextColor,
      tertiary: 'rgba(255, 255, 255, 0.48)',
      disabled: 'rgba(255, 255, 255, 0.32)',
      label: '#E6E6E8',
      warning: '#F3C969',
      alternative: '#A3D9FF',
    },
    stroke: {
      divider: 'rgba(255, 255, 255, 0.08)',
      closeButtonHover: 'rgba(255, 255, 255, 0.92)',
    },
    background: {
      primary: '#000517',
      primaryGradient: 'linear-gradient(0deg, rgba(2,6,25,1) 0%, rgba(0,0,17,1) 100%))',
      hover: 'rgba(255, 255, 255, 0.04)',
      ctaDefault: 'linear(to-r, #795EC1, #6153CC)',
      highRisk: '#E84855',
      box: 'linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)',
    },
    lighthouseHealth: {
      initial: {
        start: '#55df9c',
        end: '#55df9c',
      },
      highest: {
        start: '#56E39F',
        end: '#56E3B6',
      },
      medium: {
        start: '#56E39F',
        end: '#56C9E3',
      },
      low: {
        start: '#E37056',
        end: '#C9E356',
      },
      critical: {
        start: '#DF3E3E',
        end: '#DF3E3E',
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        bg: 'black',
      },
      '.chakra-toast__inner': {
        pointerEvents: 'none !important',
      },
      '.chakra-toast__inner .chakra-stack': {
        pointerEvents: 'auto !important',
      },
    },
  },
  components: {
    CustomButton: {
      primary: {
        borderWidth: 'none',
        borderColor: 'none',
        borderRadius: '10px',
        bgColor: 'primary',
        bgGradient: 'linear(to-r, #6153CC, #795EC1)',
        boxShadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.16)',
        hoverColor: 'rgba(255, 255, 255, 0.04)',
        hoverShadow: '0px 2px 16px rgba(97, 83, 204, 0.48), inset 0px 1px 0px rgba(255, 255, 255, 0.16)',
        bgGradientDisabled: 'linear(to-r, #57565E, #57565E)',
        textDisabled: 'text.secondary',
        borderColorDisabled: 'none',
      },
      outline: {
        borderWidth: '2px',
        borderColor: 'primary',
        borderRadius: '10px',
        bgColor: 'transparent',
        bgGradient: 'none',
        boxShadow: 'none',
        hoverColor: 'rgba(255, 255, 255, 0.04)',
        hoverShadow: 'none',
        bgGradientDisabled: 'none',
        textDisabled: 'text.disabled',
        borderColorDisabled: '#272252',
      },
    },
    SmallButton: {
      bgDefault: 'rgba(255, 255, 255, 0.32)',
      bgHover: 'rgba(255, 255, 255, 0.20)',
      textHover: secondaryTextColor,
      shadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.08)',
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#56E39F',
        },
      },
    },
    Switch: {
      baseStyle: {
        thumb: {
          bg: 'gray.300',
          width: '20px',
          height: '20px',
          _checked: {
            transform: 'translateX(20px)',
          },
        },
        track: {
          width: '40px',
          height: '20px',
          bg: 'gray.700',
          _checked: {
            bg: 'primaryGradient',
          },
        },
      },
    },
    Avatar: {
      parts: ['container'],
      baseStyle: {
        container: {
          bg: 'primaryGradient',
        },
      },
    },
    Modal: modalTheme,
    Tooltip: defineStyleConfig({
      baseStyle: {
        textColor: primaryTextColor,
        fontWeight: '700',
        fontFamily: 'Manrope, sans-serif',
        bgColor: tooltipBgColor,
        width: '190px',
        borderRadius: 10,
        px: 3,
        py: 2,
        [$tooltipArrowBg.variable]: tooltipBgColor,
      },
      variants: {
        blurred: {
          borderRadius: '10px',
          border: '1px solid rgba(255, 255, 255, 0.08)',
          bgColor: 'rgba(255, 255, 255, 0.08)',
          backdropFilter: 'blur(16px)',
        },
        wide: {
          borderRadius: '10px',
          border: '1px solid rgba(255, 255, 255, 0.08)',
          bgColor: 'rgba(255, 255, 255, 0.08)',
          backdropFilter: 'blur(16px)',
          width: '280px',
        },
      },
    }),
  },
})

export const connectKitTheme = {
  '--ck-font-family': '"Manrope", sans-serif',
  '--ck-primary-button-color': '#F7F7FF',
  '--ck-primary-button-background': 'transparent',
  '--ck-primary-button-hover-background': 'rgba(255, 255, 255, 0.04)',
  '--ck-secondary-button-background': '#6153CC',
  '--ck-secondary-button-color': '#F7F7FF',
  '--ck-secondary-button-hover-background': '#6153CC50',
  '--ck-body-background': 'linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)',
  '--ck-focus-color': '#F7F7FF',
  '--ck-spinner-color': '#6153CC',
  '--ck-overlay-backdrop-filter': 'blur(16px)',
  '--ck-modal-box-shadow':
    'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;',
  '--ck-qr-border-color': '#F7F7FF',
  '--ck-qr-dot-color': '#F7F7FF',
  '--ck-dropdown-button-background':
    'linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)',
  '--ck-dropdown-button-hover-color': '#F7F7FF',
  '--ck-body-color-muted': '#F7F7FF',
  '--ck-body-background-secondary': 'rgba(255, 255, 255, 0.04)',
  '--ck-tooltip-background': 'linear-gradient(118.15deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.90) 100%)',
  '--ck-tooltip-color': '#F7F7FF',
}
