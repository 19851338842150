import { useCallback } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { erc20Abi } from 'viem'
import { useReadContract, useWalletClient } from 'wagmi'

import { DEFAULT_TOKEN_AMOUNT_DECIMALS, formatPriceNumberWithSymbol, formatTokenAmount, getTokenImage } from '~/helpers'
import { useClaimModal } from '~/hooks/useClaimModal'
import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import Divider from '../common/Divider'
import { SmallButton } from '../layout/SmallButton'
import CloseModalButton from './CloseModalButton'
import ModalButton from './ModalButton'

const formatTokenAmountDecimals = (value?: string | number) => {
  if (!value) {
    return '-'
  }

  return `${(typeof value === 'string' ? parseFloat(value) : value).toFixed(DEFAULT_TOKEN_AMOUNT_DECIMALS)}`
}

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ClaimModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('layout')

  const { claim, isPending, isBroadcasting, distributionToken } = useClaimModal()

  const handleClaimClick = async () => {
    const success = await claim()

    if (success) {
      onClose()
    }
  }

  const {
    underlyingSymbol,
    unclaimedBalance,
    totalBalance,
    balance,
    underlyingDecimals,
    underlyingTokenAddress,
    distributionTokenPrice,
  } = distributionToken

  const isClaimEnabled = Number(formatTokenAmountDecimals(unclaimedBalance)) > 0

  const { data: walletClient } = useWalletClient()

  const { data: symbol } = useReadContract({
    functionName: 'symbol',
    abi: erc20Abi,
    address: underlyingTokenAddress,
    query: {
      enabled: !!underlyingTokenAddress,
    },
  })

  const addTokenToWallet = useCallback(async () => {
    if (!underlyingTokenAddress || !underlyingDecimals || !walletClient || !symbol) {
      return
    }

    try {
      await walletClient.watchAsset({
        type: 'ERC20',
        options: {
          address: underlyingTokenAddress,
          symbol,
          decimals: underlyingDecimals,
        },
      })
    } catch (e) {
      Analytics.trackException(e)
    }
  }, [underlyingDecimals, underlyingTokenAddress, walletClient, symbol])

  const shouldShowPendingStatus = isPending || isBroadcasting

  const getButtonText = () => {
    if (isPending) {
      return (
        <>
          <Spinner size="sm" mr={2} />
          {t('claimModal.approveInWallet')}
        </>
      )
    }

    if (isBroadcasting) {
      return (
        <>
          <Spinner size="sm" mr={2} />
          {t('claimModal.transactionPending')}
        </>
      )
    }

    if (isClaimEnabled) {
      return `${t('claimModal.claim')} ${formatTokenAmountDecimals(unclaimedBalance)} ${
        underlyingSymbol || t('claimModal.deeprTokenDefaultSymbol')
      }`
    }

    return t('claimModal.noUnclaimedBalance')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'full', md: 'md' }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={{ base: '16px', md: '20px' }}>
          {t('claimModal.deeprTokenName')} {t('claimModal.balance')}
          <CloseModalButton />
        </ModalHeader>
        <ModalBody p={6}>
          <Stack spacing={6} alignItems="center" w="full">
            <Avatar mt={6} size="xl" name={underlyingSymbol} src={getTokenImage(underlyingSymbol)} bg="transparent" />
            {totalBalance !== undefined && (
              <Box textAlign="center">
                <Text fontSize="xl" fontWeight="700">
                  {formatTokenAmount(totalBalance, distributionToken.underlyingSymbol, 6)}
                </Text>
                {distributionTokenPrice && (
                  <Text color="text.tertiary" fontWeight="600">
                    {formatPriceNumberWithSymbol(distributionTokenPrice * totalBalance)}
                  </Text>
                )}
              </Box>
            )}
            <SmallButton
              onClick={addTokenToWallet}
              mt="12px"
              py="4px"
              px="12px"
              color="rgba(255, 255, 255, 0.72)"
              fontSize="12px"
            >
              {t('claimModal.addTokenToWallet')}
            </SmallButton>
            <Divider />
            <Stack spacing={2} alignItems="flex-start" w="full">
              <Flex w="full" justifyContent="space-between">
                <Text fontWeight="500" color="text.secondary">
                  {t('claimModal.walletBalance')}
                </Text>
                <Text fontWeight="600" color="text.primary">
                  {formatTokenAmountDecimals(balance)}
                </Text>
              </Flex>
              <Flex w="full" justifyContent="space-between">
                <Text fontWeight="500" color="text.secondary">
                  {t('claimModal.unclaimed')}
                </Text>
                <Text fontWeight="600" color="text.primary">
                  {formatTokenAmountDecimals(unclaimedBalance)}
                </Text>
              </Flex>
              <Flex w="full" justifyContent="space-between">
                <Text fontWeight="500" color="text.secondary">
                  {t('claimModal.price')}
                </Text>
                <Text fontWeight="600" color="text.primary">
                  {formatPriceNumberWithSymbol(distributionTokenPrice || 0)}
                </Text>
              </Flex>
            </Stack>
            <ModalButton isDisabled={!isClaimEnabled || shouldShowPendingStatus} onClick={handleClaimClick}>
              {getButtonText()}
            </ModalButton>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ClaimModal
