import { isMobile } from 'react-device-detect'
import { ButtonProps } from '@chakra-ui/react'
import { omit } from 'lodash'
import { useAccount } from 'wagmi'

import { ButtonSize, ButtonVariant, CustomButton } from '../layout/CustomButton'
import { CustomConnectKitButton } from '../layout/CustomConnectKitButton'

const ModalButton = ({
  children,
  isSecondary = false,
  ...props
}: ButtonProps & { isSecondary?: boolean; children: React.ReactNode }) => {
  const { isConnected } = useAccount()

  const mobileProps = { position: 'absolute', bottom: isSecondary ? '24px' : '88px', w: 'calc(100% - 32px)' } as const

  if (!isConnected) {
    return (
      <CustomConnectKitButton
        mt="33px !important"
        {...omit(props, 'onClick')}
        size={ButtonSize.LARGE}
        w="full"
        {...(isMobile && { ...mobileProps })}
        isDisabled={false}
      />
    )
  }

  return (
    <CustomButton
      mt="33px !important"
      {...props}
      size={ButtonSize.LARGE}
      variant={isSecondary ? ButtonVariant.OUTLINE : ButtonVariant.PRIMARY}
      w="full"
      type="submit"
      {...(isMobile && { ...mobileProps })}
    >
      {children}
    </CustomButton>
  )
}

export default ModalButton
