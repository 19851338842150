import { FaDiscord, FaMedium, FaXTwitter } from 'react-icons/fa6'
import { HStack, Icon } from '@chakra-ui/react'

import { DISCORD_URL, MEDIUM_URL, TWITTERX_URL } from '~/constants'

const SocialLinks = () => {
  return (
    <HStack spacing={{ base: 4, md: 6 }}>
      <a href={DISCORD_URL} target="_blank" rel="noreferrer">
        <Icon as={FaDiscord} boxSize={5} mt={2} color="text.secondary" />
      </a>
      <a href={TWITTERX_URL} target="_blank" rel="noreferrer">
        <Icon as={FaXTwitter} boxSize={5} mt={2} color="text.secondary" />
      </a>
      <a href={MEDIUM_URL} target="_blank" rel="noreferrer">
        <Icon as={FaMedium} boxSize={5} mt={2} color="text.secondary" />
      </a>
    </HStack>
  )
}

export default SocialLinks
