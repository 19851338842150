export default [
  {
    type: 'function',
    name: 'cTokenBalances',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'cToken' },
      { type: 'address', name: 'account' },
    ],
    outputs: [
      {
        type: 'tuple',
        components: [
          { type: 'address', name: 'cToken' },
          { type: 'uint256', name: 'balanceOf' },
          { type: 'uint256', name: 'borrowBalanceCurrent' },
          { type: 'uint256', name: 'balanceOfUnderlying' },
          { type: 'uint256', name: 'tokenBalance' },
          { type: 'uint256', name: 'tokenAllowance' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'cTokenBalancesAll',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address[]', name: 'cTokens' },
      { type: 'address', name: 'account' },
    ],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'address', name: 'cToken' },
          { type: 'uint256', name: 'balanceOf' },
          { type: 'uint256', name: 'borrowBalanceCurrent' },
          { type: 'uint256', name: 'balanceOfUnderlying' },
          { type: 'uint256', name: 'tokenBalance' },
          { type: 'uint256', name: 'tokenAllowance' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'cTokenMetadata',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'cToken' }],
    outputs: [
      {
        type: 'tuple',
        components: [
          { type: 'address', name: 'cToken' },
          { type: 'uint256', name: 'exchangeRateCurrent' },
          { type: 'uint256', name: 'supplyRatePerSecond' },
          { type: 'uint256', name: 'borrowRatePerSecond' },
          { type: 'uint256', name: 'reserveFactorMantissa' },
          { type: 'uint256', name: 'totalBorrows' },
          { type: 'uint256', name: 'totalReserves' },
          { type: 'uint256', name: 'totalSupply' },
          { type: 'uint256', name: 'totalCash' },
          { type: 'bool', name: 'isListed' },
          { type: 'uint256', name: 'collateralFactorMantissa' },
          { type: 'address', name: 'underlyingAssetAddress' },
          { type: 'uint256', name: 'cTokenDecimals' },
          { type: 'uint256', name: 'underlyingDecimals' },
          { type: 'uint256', name: 'compSupplySpeed' },
          { type: 'uint256', name: 'compBorrowSpeed' },
          { type: 'uint256', name: 'borrowCap' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'cTokenMetadataAll',
    constant: false,
    payable: false,
    inputs: [{ type: 'address[]', name: 'cTokens' }],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'address', name: 'cToken' },
          { type: 'uint256', name: 'exchangeRateCurrent' },
          { type: 'uint256', name: 'supplyRatePerSecond' },
          { type: 'uint256', name: 'borrowRatePerSecond' },
          { type: 'uint256', name: 'reserveFactorMantissa' },
          { type: 'uint256', name: 'totalBorrows' },
          { type: 'uint256', name: 'totalReserves' },
          { type: 'uint256', name: 'totalSupply' },
          { type: 'uint256', name: 'totalCash' },
          { type: 'bool', name: 'isListed' },
          { type: 'uint256', name: 'collateralFactorMantissa' },
          { type: 'address', name: 'underlyingAssetAddress' },
          { type: 'uint256', name: 'cTokenDecimals' },
          { type: 'uint256', name: 'underlyingDecimals' },
          { type: 'uint256', name: 'compSupplySpeed' },
          { type: 'uint256', name: 'compBorrowSpeed' },
          { type: 'uint256', name: 'borrowCap' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'cTokenUnderlyingPrice',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'cToken' }],
    outputs: [
      {
        type: 'tuple',
        components: [
          { type: 'address', name: 'cToken' },
          { type: 'uint256', name: 'underlyingPrice' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'cTokenUnderlyingPriceAll',
    constant: false,
    payable: false,
    inputs: [{ type: 'address[]', name: 'cTokens' }],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'address', name: 'cToken' },
          { type: 'uint256', name: 'underlyingPrice' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getAccountLimits',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'comptroller' },
      { type: 'address', name: 'account' },
    ],
    outputs: [
      {
        type: 'tuple',
        components: [
          { type: 'address[]', name: 'markets' },
          { type: 'uint256', name: 'liquidity' },
          { type: 'uint256', name: 'shortfall' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getCompBalanceMetadata',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'comp' },
      { type: 'address', name: 'account' },
    ],
    outputs: [
      {
        type: 'tuple',
        components: [
          { type: 'uint256', name: 'balance' },
          { type: 'uint256', name: 'votes' },
          { type: 'address', name: 'delegate' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getCompBalanceMetadataExt',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'comp' },
      { type: 'address', name: 'comptroller' },
      { type: 'address', name: 'account' },
    ],
    outputs: [
      {
        type: 'tuple',
        components: [
          { type: 'uint256', name: 'balance' },
          { type: 'uint256', name: 'votes' },
          { type: 'address', name: 'delegate' },
          { type: 'uint256', name: 'allocated' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getCompVotes',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'comp' },
      { type: 'address', name: 'account' },
      { type: 'uint32[]', name: 'blockNumbers' },
    ],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'uint256', name: 'blockNumber' },
          { type: 'uint256', name: 'votes' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getGovBravoProposals',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'governor' },
      { type: 'uint256[]', name: 'proposalIds' },
    ],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'uint256', name: 'proposalId' },
          { type: 'address', name: 'proposer' },
          { type: 'uint256', name: 'eta' },
          { type: 'address[]', name: 'targets' },
          { type: 'uint256[]', name: 'values' },
          { type: 'string[]', name: 'signatures' },
          { type: 'bytes[]', name: 'calldatas' },
          { type: 'uint256', name: 'startBlock' },
          { type: 'uint256', name: 'endBlock' },
          { type: 'uint256', name: 'forVotes' },
          { type: 'uint256', name: 'againstVotes' },
          { type: 'uint256', name: 'abstainVotes' },
          { type: 'bool', name: 'canceled' },
          { type: 'bool', name: 'executed' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getGovBravoReceipts',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'governor' },
      { type: 'address', name: 'voter' },
      { type: 'uint256[]', name: 'proposalIds' },
    ],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'uint256', name: 'proposalId' },
          { type: 'bool', name: 'hasVoted' },
          { type: 'uint8', name: 'support' },
          { type: 'uint96', name: 'votes' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getGovProposals',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'governor' },
      { type: 'uint256[]', name: 'proposalIds' },
    ],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'uint256', name: 'proposalId' },
          { type: 'address', name: 'proposer' },
          { type: 'uint256', name: 'eta' },
          { type: 'address[]', name: 'targets' },
          { type: 'uint256[]', name: 'values' },
          { type: 'string[]', name: 'signatures' },
          { type: 'bytes[]', name: 'calldatas' },
          { type: 'uint256', name: 'startBlock' },
          { type: 'uint256', name: 'endBlock' },
          { type: 'uint256', name: 'forVotes' },
          { type: 'uint256', name: 'againstVotes' },
          { type: 'bool', name: 'canceled' },
          { type: 'bool', name: 'executed' },
        ],
      },
    ],
  },
  {
    type: 'function',
    name: 'getGovReceipts',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'governor' },
      { type: 'address', name: 'voter' },
      { type: 'uint256[]', name: 'proposalIds' },
    ],
    outputs: [
      {
        type: 'tuple[]',
        components: [
          { type: 'uint256', name: 'proposalId' },
          { type: 'bool', name: 'hasVoted' },
          { type: 'bool', name: 'support' },
          { type: 'uint96', name: 'votes' },
        ],
      },
    ],
  },
] as const
