export default [
  { inputs: [], name: 'AcceptAdminPendingAdminCheck', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'actualAddAmount', type: 'uint256' }],
    name: 'AddReservesFactorFreshCheck',
    type: 'error',
  },
  { inputs: [], name: 'BorrowCashNotAvailable', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'BorrowComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'BorrowFreshnessCheck', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'LiquidateAccrueBorrowInterestFailed',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'LiquidateAccrueCollateralInterestFailed',
    type: 'error',
  },
  { inputs: [], name: 'LiquidateCloseAmountIsUintMax', type: 'error' },
  { inputs: [], name: 'LiquidateCloseAmountIsZero', type: 'error' },
  { inputs: [], name: 'LiquidateCollateralFreshnessCheck', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'LiquidateComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'LiquidateFreshnessCheck', type: 'error' },
  { inputs: [], name: 'LiquidateLiquidatorIsBorrower', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'LiquidateRepayBorrowFreshFailed',
    type: 'error',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'LiquidateSeizeComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'LiquidateSeizeLiquidatorIsBorrower', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'MintComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'MintFreshnessCheck', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'RedeemComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'RedeemFreshnessCheck', type: 'error' },
  { inputs: [], name: 'RedeemTransferOutNotPossible', type: 'error' },
  { inputs: [], name: 'ReduceReservesAdminCheck', type: 'error' },
  { inputs: [], name: 'ReduceReservesCashNotAvailable', type: 'error' },
  { inputs: [], name: 'ReduceReservesCashValidation', type: 'error' },
  { inputs: [], name: 'ReduceReservesFreshCheck', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'RepayBorrowComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'RepayBorrowFreshnessCheck', type: 'error' },
  { inputs: [], name: 'SetComptrollerOwnerCheck', type: 'error' },
  { inputs: [], name: 'SetInterestRateModelFreshCheck', type: 'error' },
  { inputs: [], name: 'SetInterestRateModelOwnerCheck', type: 'error' },
  { inputs: [], name: 'SetPendingAdminOwnerCheck', type: 'error' },
  { inputs: [], name: 'SetReserveFactorAdminCheck', type: 'error' },
  { inputs: [], name: 'SetReserveFactorBoundsCheck', type: 'error' },
  { inputs: [], name: 'SetReserveFactorFreshCheck', type: 'error' },
  { inputs: [], name: 'TimestampPriorLastAccrual', type: 'error' },
  { inputs: [], name: 'TimestampTooLarge', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'errorCode', type: 'uint256' }],
    name: 'TransferComptrollerRejection',
    type: 'error',
  },
  { inputs: [], name: 'TransferNotAllowed', type: 'error' },
  { inputs: [], name: 'TransferNotEnough', type: 'error' },
  { inputs: [], name: 'TransferTooMuch', type: 'error' },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'cashPrior', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'interestAccumulated', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'borrowIndex', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'totalBorrows', type: 'uint256' },
    ],
    name: 'AccrueInterest',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'spender', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'borrower', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'borrowAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'accountBorrows', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'totalBorrows', type: 'uint256' },
    ],
    name: 'Borrow',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'liquidator', type: 'address' },
      { indexed: false, internalType: 'address', name: 'borrower', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'cTokenCollateral', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'seizeTokens', type: 'uint256' },
    ],
    name: 'LiquidateBorrow',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'minter', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'mintAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'mintTokens', type: 'uint256' },
    ],
    name: 'Mint',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'oldAdmin', type: 'address' },
      { indexed: false, internalType: 'address', name: 'newAdmin', type: 'address' },
    ],
    name: 'NewAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract ComptrollerInterface',
        name: 'oldComptroller',
        type: 'address',
      },
      { indexed: false, internalType: 'contract ComptrollerInterface', name: 'newComptroller', type: 'address' },
    ],
    name: 'NewComptroller',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract InterestRateModel',
        name: 'oldInterestRateModel',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'contract InterestRateModel',
        name: 'newInterestRateModel',
        type: 'address',
      },
    ],
    name: 'NewMarketInterestRateModel',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'oldPendingAdmin', type: 'address' },
      { indexed: false, internalType: 'address', name: 'newPendingAdmin', type: 'address' },
    ],
    name: 'NewPendingAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'oldReserveFactorMantissa', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newReserveFactorMantissa', type: 'uint256' },
    ],
    name: 'NewReserveFactor',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'redeemer', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'redeemAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'redeemTokens', type: 'uint256' },
    ],
    name: 'Redeem',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'payer', type: 'address' },
      { indexed: false, internalType: 'address', name: 'borrower', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'accountBorrows', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'totalBorrows', type: 'uint256' },
    ],
    name: 'RepayBorrow',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'benefactor', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'addAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newTotalReserves', type: 'uint256' },
    ],
    name: 'ReservesAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'admin', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'reduceAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'newTotalReserves', type: 'uint256' },
    ],
    name: 'ReservesReduced',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [],
    name: 'NO_ERROR',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: '_acceptAdmin',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'addAmount', type: 'uint256' }],
    name: '_addReserves',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'compLikeDelegatee', type: 'address' }],
    name: '_delegateCompLikeTo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'reduceAmount', type: 'uint256' }],
    name: '_reduceReserves',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract ComptrollerInterface', name: 'newComptroller', type: 'address' }],
    name: '_setComptroller',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract InterestRateModel', name: 'newInterestRateModel', type: 'address' }],
    name: '_setInterestRateModel',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address payable', name: 'newPendingAdmin', type: 'address' }],
    name: '_setPendingAdmin',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'newReserveFactorMantissa', type: 'uint256' }],
    name: '_setReserveFactor',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'accrualBlockTimestamp',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'accrueInterest',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'admin',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'balanceOfUnderlying',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'borrowAmount', type: 'uint256' }],
    name: 'borrow',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'borrowBalanceCurrent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'borrowBalanceStored',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'borrowIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'borrowRatePerSecond',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'comptroller',
    outputs: [{ internalType: 'contract ComptrollerInterface', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'exchangeRateCurrent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'exchangeRateStored',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'getAccountSnapshot',
    outputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCash',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'underlying_', type: 'address' },
      { internalType: 'contract ComptrollerInterface', name: 'comptroller_', type: 'address' },
      { internalType: 'contract InterestRateModel', name: 'interestRateModel_', type: 'address' },
      { internalType: 'uint256', name: 'initialExchangeRateMantissa_', type: 'uint256' },
      { internalType: 'string', name: 'name_', type: 'string' },
      { internalType: 'string', name: 'symbol_', type: 'string' },
      { internalType: 'uint8', name: 'decimals_', type: 'uint8' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract ComptrollerInterface', name: 'comptroller_', type: 'address' },
      { internalType: 'contract InterestRateModel', name: 'interestRateModel_', type: 'address' },
      { internalType: 'uint256', name: 'initialExchangeRateMantissa_', type: 'uint256' },
      { internalType: 'string', name: 'name_', type: 'string' },
      { internalType: 'string', name: 'symbol_', type: 'string' },
      { internalType: 'uint8', name: 'decimals_', type: 'uint8' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'interestRateModel',
    outputs: [{ internalType: 'contract InterestRateModel', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'isCToken',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
      { internalType: 'contract CTokenInterface', name: 'cTokenCollateral', type: 'address' },
    ],
    name: 'liquidateBorrow',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'mintAmount', type: 'uint256' }],
    name: 'mint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'pendingAdmin',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'protocolSeizeShareMantissa',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'redeemTokens', type: 'uint256' }],
    name: 'redeem',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'redeemAmount', type: 'uint256' }],
    name: 'redeemUnderlying',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'repayAmount', type: 'uint256' }],
    name: 'repayBorrow',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'repayAmount', type: 'uint256' },
    ],
    name: 'repayBorrowBehalf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'reserveFactorMantissa',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'liquidator', type: 'address' },
      { internalType: 'address', name: 'borrower', type: 'address' },
      { internalType: 'uint256', name: 'seizeTokens', type: 'uint256' },
    ],
    name: 'seize',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'supplyRatePerSecond',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract EIP20NonStandardInterface', name: 'token', type: 'address' }],
    name: 'sweepToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalBorrows',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalBorrowsCurrent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalReserves',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'src', type: 'address' },
      { internalType: 'address', name: 'dst', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'underlying',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const
