import { Address } from 'viem'

import { Network } from '~/lib/networks'

export const addresses: {
  [key: number]: {
    comptroller: Address // Always use the Unitroller address here, not comptroller
    lens: Address
    maximillion?: Address
    faucet?: Address
    subieMintToken?: Address
    subieMinter?: Address
    liquidate?: Address
    staking?: Address
    wrap?: Address
    bridgeAdapter?: Address
  }
} = {
  [Network.Fantom_Testnet]: {
    comptroller: '0x09BC6d6543D71600f2C11600841CE7E88C264EEC',
    lens: '0xD4F004891cea2BbEcC4B5A596915DE54D0fc00a2',
    maximillion: '0x4fD33b7c604Af033Efd66c85d33686CC2Ee3a43A',
  },
  [Network.Shimmer_Testnet]: {
    comptroller: '0x1A1062603DeB11E84D30d300ad62F91d71dEe32c',
    lens: '0x20127dED953Fe7A5C9d37585be2f17B4F7F28A6E',
    maximillion: '0x9B43Ee672aDD193820504475A3233B0dD3676947',
    faucet: '0xA56C09640e20C07069fCa97Cb88fFa30e4167142',
    subieMintToken: '0xA0E1A63E39D2C97d93c79115234c4cdFE6f33067',
    subieMinter: '0x24b7b369CCB384E451c9bcBF41dE4C74eC21F43C',
    liquidate: '0x628515BF7551a6Cd71ab9B60b83B199AE3b0f616',
    staking: '0x743af2cAedC99f122FE346F4c7361490B4A07662',
  },
  [Network.Shimmer]: {
    comptroller: '0xF7E452A8685D57083Edf4e4CC8064EcDcF71D7B7',
    lens: '0x45cff52aD0d14D2f4cC7075998D5DAAb47C89D04',
    subieMintToken: '0x6E0e6A580C96d42A4a3768bF59871EE9b423B053',
    subieMinter: '0x501221B18ee951725f76f654a7c66007043070f1',
    liquidate: '0xF2ec99372F5Fd23B588A8AE5161B03E172017044',
    staking: '0xaF9330ACe93F94d708aAD94BFd68D41a242D7f8B',
    bridgeAdapter: '0x84812267B4561a4658A51e863214528CC2b88622',
  },
  [Network.IOTA_Testnet]: {
    comptroller: '0x54040978F7fE0ff209312FE397db1083B08eE55d',
    lens: '0x7D171148e8d7F8aDfbF7B9F685e5298b7780e4Aa',
    maximillion: '0x999c7d8C634014497EF167207a9d6f4a3bf88Cf7',
    staking: '0xaF9330ACe93F94d708aAD94BFd68D41a242D7f8B',
    wrap: '0xB2E0DfC4820cc55829C71529598530E177968613',
    liquidate: '0xd6490024775826763bc3ff4665550971970998E6',
  },
  [Network.IOTA]: {
    comptroller: '0xee07121d97FDEA35675e02017837a7a43aeDa48F',
    lens: '0xDe131E97c396ED48A27ebc9E2A93824216566675',
    staking: '0x18041C94D941177574A1D79b0c55DD894E9E02bC',
    wrap: '0x6e47f8d48a01b44DF3fFF35d258A10A3AEdC114c',
    liquidate: '0x',
    bridgeAdapter: '0x4Eb8E03461360C2AcBb4B5963a18b8f9faEE8221', // OFT Adapter
  },
}
