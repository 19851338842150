import React, { LegacyRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useParallax } from 'react-scroll-parallax'
import { Box } from '@chakra-ui/react'

const ParallaxParticles = () => {
  const parallax = useParallax({
    speed: !isMobile ? 30 : 0,
  })

  return (
    <Box
      ref={parallax.ref as LegacyRef<HTMLDivElement>}
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={0}
      bgImage="url('/assets/particles.png')"
    />
  )
}

export default ParallaxParticles
