import { Dictionary, find, keyBy, map } from 'lodash'
import useSWR, { SWRResponse } from 'swr'
import { Address, formatUnits, PublicClient } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

import { DEFAULT_DECIMALS } from '~/constants'
import { addresses } from '~/constants/addresses'
import { tokens, UNDERLYING_PRICE_BASE_DECIMALS } from '~/constants/tokens'
import CompoundLens from '~/lib/abis/CompoundLens'
import { AccountLimits, CTokenUnderlyingPriceAll } from '~/types/contracts'
import useSupportedNetworkId from './useSupportedNetworkId'

type CTokenUnderlyingPriceMapType = Dictionary<{ cToken: Address; underlyingPrice: number }>

const REFRESH_INTERVAL = 13000

function formatUnderlyingPrices(
  chainId: number,
  cTokenUnderlyingPriceAll?: CTokenUnderlyingPriceAll,
  accountLimits?: AccountLimits
): { cTokenUnderlyingPriceMap: CTokenUnderlyingPriceMapType; accountLiquidity: number } {
  const networkTokens = tokens[chainId]

  const cTokenUnderlyingPriceFormatted = map(
    cTokenUnderlyingPriceAll,
    ({ cToken, underlyingPrice: underlyingPriceBigNumber }) => {
      const decimals = networkTokens[cToken]?.underlyingDecimals

      if (!decimals) {
        return { cToken, underlyingPrice: -1 }
      }

      return {
        cToken,
        underlyingPrice: +formatUnits(underlyingPriceBigNumber, UNDERLYING_PRICE_BASE_DECIMALS - decimals),
      }
    }
  ).filter(({ underlyingPrice }) => underlyingPrice >= 0)

  const cTokenUnderlyingPriceMap = keyBy(cTokenUnderlyingPriceFormatted, 'cToken')
  const accountLiquidity = +formatUnits(accountLimits?.liquidity ?? BigInt(0), DEFAULT_DECIMALS)

  const nativeToken = find(networkTokens, { isNativeToken: true })

  if (!nativeToken) return { cTokenUnderlyingPriceMap, accountLiquidity }

  const nativeTokenPrice = cTokenUnderlyingPriceMap[nativeToken.address]?.underlyingPrice

  // Checks if retrieved underlying prices are measured in Native Token unit
  if (nativeTokenPrice === 1) {
    const anchorToken = find(networkTokens, { isPriceAnchor: true })

    if (!anchorToken) return { cTokenUnderlyingPriceMap, accountLiquidity }

    const anchorTokenPrice = cTokenUnderlyingPriceMap[anchorToken.address].underlyingPrice

    const nativeTokenPriceUSD = nativeTokenPrice / anchorTokenPrice

    const cTokenUnderlyingPriceAllOldOracle = cTokenUnderlyingPriceFormatted?.map(
      ({ cToken, underlyingPrice: underlyingPriceRaw }) => ({
        cToken,
        underlyingPrice: underlyingPriceRaw * nativeTokenPriceUSD,
      })
    )

    return {
      cTokenUnderlyingPriceMap: keyBy(cTokenUnderlyingPriceAllOldOracle, 'cToken'),
      accountLiquidity: accountLiquidity * nativeTokenPriceUSD,
    }
  }

  return { cTokenUnderlyingPriceMap, accountLiquidity }
}

/**
 * Fetcher
 */

const fetcher = async (chainId: number, publicClient: PublicClient, account?: string) => {
  const addressesByNetwork = addresses[chainId]
  const allMarkets = map(tokens[chainId], 'address')

  const [cTokenUnderlyingPriceAll, accountLimits] = await Promise.all([
    publicClient.simulateContract({
      abi: CompoundLens,
      address: addressesByNetwork.lens,
      functionName: 'cTokenUnderlyingPriceAll',
      args: [allMarkets],
    }),
    account
      ? publicClient.simulateContract({
          abi: CompoundLens,
          address: addressesByNetwork.lens,
          functionName: 'getAccountLimits',
          args: [addressesByNetwork.comptroller, account],
        })
      : undefined,
  ])

  return {
    cTokenUnderlyingPriceAll: cTokenUnderlyingPriceAll.result as CTokenUnderlyingPriceAll,
    accountLimits: accountLimits?.result as AccountLimits | undefined,
    allMarkets,
  }
}

/**
 * useUnderlyingPriceData Hook
 */

export const useUnderlyingPriceData = (): {
  cTokenUnderlyingPriceMap: CTokenUnderlyingPriceMapType
  accountLiquidity: number
  accountLimits?: AccountLimits
  isLoading: boolean
  error: SWRResponse['error']
  refetch: () => void
} => {
  const { address } = useAccount()
  const currentChainId = useSupportedNetworkId()

  const publicClient = usePublicClient({ chainId: currentChainId })

  const { data, mutate, error } = useSWR(
    `priceData-${currentChainId}${address || ''}`,
    () => fetcher(currentChainId, publicClient as PublicClient, address),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

  const { cTokenUnderlyingPriceMap, accountLiquidity } = formatUnderlyingPrices(
    currentChainId,
    data?.cTokenUnderlyingPriceAll,
    data?.accountLimits
  )

  return {
    cTokenUnderlyingPriceMap,
    accountLiquidity,
    accountLimits: data?.accountLimits,
    refetch: mutate,
    isLoading: !data && !error,
    error,
  }
}
