export enum Network {
  Mainnet = 1,
  BSC_Testnet = 97,
  BSC = 56,
  Polygon = 137,
  Fantom_Opera = 250,
  Fantom_Testnet = 4002,
  Shimmer_Testnet = 1073,
  Shimmer = 148,
  IOTA_Testnet = 1075,
  IOTA = 8822,
}

export const FAUCET_NETWORKS = [Network.Shimmer_Testnet]

export const DEFAULT_NETWORK_ID = Network.IOTA

export const NetworksNames: Record<Network, string> = {
  [Network.Mainnet]: 'Mainnet',
  [Network.Polygon]: 'Polygon',
  [Network.BSC_Testnet]: 'Binance Smart Chain',
  [Network.BSC]: 'Binance Smart Chain',
  [Network.Fantom_Opera]: 'Fantom Opera',
  [Network.Fantom_Testnet]: 'FTM Testnet',
  [Network.Shimmer_Testnet]: 'Shimmer EVM Testnet',
  [Network.Shimmer]: 'Shimmer EVM',
  [Network.IOTA_Testnet]: 'IOTA EVM Testnet',
  [Network.IOTA]: 'IOTA EVM',
}
