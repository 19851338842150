import { mapValues } from 'lodash'

import { FAUCET_SUPPLY_EXPIRATION_LAPSE } from '~/constants/faucet'

const GAS_ESTIMATION_KEY = 'deepr-supply-gas-fee-estimation'
const FAUCET_SUPPLY_KEY = 'deepr-faucet-supply-timestamp-'

interface SuppliedAddress {
  address: string
  ip: string
  timestamp: number
}

export const checkIfWasSuppliedByFaucetToday = async (address: string, ip: string) => {
  const timestampAddress = await localStorage.getItem(`${FAUCET_SUPPLY_KEY}${address}`)
  const timestampIp = await localStorage.getItem(`${FAUCET_SUPPLY_KEY}${ip}`)

  if (!timestampAddress && !timestampIp) {
    return false
  }

  const now = Date.now()

  const wasAddressSuppliedToday = Number(timestampAddress) + FAUCET_SUPPLY_EXPIRATION_LAPSE > now
  const wasIpSuppliedToday = Number(timestampIp) + FAUCET_SUPPLY_EXPIRATION_LAPSE > now

  return wasAddressSuppliedToday || wasIpSuppliedToday
}

export const setAsSuppliedByFaucet = (data: SuppliedAddress) => {
  window.localStorage.setItem(`${FAUCET_SUPPLY_KEY}${data.address}`, data.timestamp.toString())
  window.localStorage.setItem(`${FAUCET_SUPPLY_KEY}${data.ip}`, data.timestamp.toString())
}

interface GasEstimation {
  gasFee: number
  gasFeeUnits: number
}

export const getSupplyGasFeeEstimation = async () => {
  return mapValues(JSON.parse(localStorage.getItem(GAS_ESTIMATION_KEY) ?? '{}'), (value) => parseFloat(value))
}

export const setSupplyGasFeeEstimation = (gasEstimation: GasEstimation) => {
  window.localStorage.setItem(
    GAS_ESTIMATION_KEY,
    JSON.stringify({
      gasFee: gasEstimation.gasFee,
      gasFeeUnits: gasEstimation.gasFeeUnits,
      timestamp: Date.now(),
    })
  )
}
