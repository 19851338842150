import { Address, formatUnits, getContract } from 'viem'
import { useAccount, usePublicClient, useWalletClient } from 'wagmi'

import { SAFE_WALLET_DEFAULT_TX_OPTIONS } from '~/constants'
import { addresses } from '~/constants/addresses'
import { CustomError } from '~/helpers'
import Comptroller from '~/lib/abis/Comptroller'
import { useTranslation } from '~/lib/i18n'
import useSupportedNetworkId from './useSupportedNetworkId'

/**
 * Comptroller Error Codes Mapper
 * [ErrorCode]: i18n key
 */

export const comptrollerErrorCodes: { [key: string]: string } = {
  0: 'comptrollerErrors.noError',
  1: 'comptrollerErrors.unauthorized',
  2: 'comptrollerErrors.comptrollerMismatch',
  3: 'comptrollerErrors.insufficientShortfall',
  4: 'comptrollerErrors.insufficientLiquidity',
  5: 'comptrollerErrors.invalidCloseFactor',
  6: 'comptrollerErrors.invalidCollateralFactor',
  7: 'comptrollerErrors.invalidLiquidationIncentive',
  8: 'comptrollerErrors.marketNotEntered',
  9: 'comptrollerErrors.marketNotListed',
  10: 'comptrollerErrors.marketAlreadyListed',
  11: 'comptrollerErrors.mathError',
  12: 'comptrollerErrors.nonzeroBorrowBalance',
  13: 'comptrollerErrors.priceError',
  14: 'comptrollerErrors.rejection',
  15: 'comptrollerErrors.snapshotError',
  16: 'comptrollerErrors.tooManyAssets',
  17: 'comptrollerErrors.tooMuchRepay',
}

/**
 * useComptrollerContractWithSigner Hook
 */

export function useComptrollerContractWithSigner() {
  const { data: walletClient } = useWalletClient()
  const publicClient = usePublicClient()
  const currentChainId = useSupportedNetworkId()
  const account = useAccount()

  const comptrollerAddress = addresses[currentChainId]?.comptroller
  const { t } = useTranslation('dashboard')

  if (!publicClient || !walletClient || !comptrollerAddress) {
    return {}
  }

  const comptrollerContract = getContract({
    address: comptrollerAddress,
    abi: Comptroller,
    client: {
      public: publicClient,
      wallet: walletClient,
    },
  })

  const enterMarkets = async (params: Address[]) => {
    const simulationResult = (await comptrollerContract.simulate.enterMarkets([params])).result

    const responseCode = +formatUnits(simulationResult[0], 0)

    if (responseCode != 0) {
      throw new CustomError(t(comptrollerErrorCodes[responseCode]) || t('comptrollerErrors.generic'), responseCode)
    }

    return await comptrollerContract.write.enterMarkets([params], SAFE_WALLET_DEFAULT_TX_OPTIONS)
  }

  const exitMarket = async (params: Address) => {
    const simulationResult = (await comptrollerContract.simulate.exitMarket([params], { account: account.address }))
      .result

    const responseCode = +formatUnits(simulationResult, 0)

    if (responseCode != 0) {
      throw new CustomError(t(comptrollerErrorCodes[responseCode]) || t('comptrollerErrors.generic'), responseCode)
    }

    return await comptrollerContract.write.exitMarket([params], SAFE_WALLET_DEFAULT_TX_OPTIONS)
  }

  const claimDeepr = (address: Address) => {
    // Does not handle errors since it reverts upon any failures
    return comptrollerContract.write.claimDeepr([address], SAFE_WALLET_DEFAULT_TX_OPTIONS)
  }

  return { enterMarkets, exitMarket, claimDeepr }
}
