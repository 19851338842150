import { Button, ButtonProps } from '@chakra-ui/react'

import { theme } from '~/styles/theme'

export const SmallButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      textTransform="uppercase"
      fontSize="xs"
      fontWeight="bold"
      maxH={6}
      px={3}
      py={1}
      textDecoration="none"
      bgColor={theme.components.SmallButton.bgDefault}
      _hover={{
        textColor: theme.components.SmallButton.textHover,
        textDecoration: 'none',
        bgColor: theme.components.SmallButton.bgHover,
        boxShadow: theme.components.SmallButton.shadow,
      }}
      boxShadow={theme.components.SmallButton.shadow}
      display="flex"
      {...props}
    />
  )
}
