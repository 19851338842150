import { useState } from 'react'
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi'
import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Icon, IconButton, Spinner, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { SmallButton } from '~/components/layout/SmallButton'
import { useTranslation } from '~/lib/i18n'
import { theme } from '~/styles/theme'

/**
 *  Helpers
 */

const getAnimationExitPosition = (dragOffset: number) => {
  if (dragOffset > 0) {
    return '100vh'
  }
  if (dragOffset < 0) {
    return '-160vh'
  }

  return '0'
}

/**
 * Types
 */

interface Props {
  title: string
  description?: string
  variant: string
  onActionClick?: () => void
  onClose?: () => void
  actionLabel?: string
}

export enum ToastType {
  FAILURE = 'failure',
  SUCCESS = 'success',
  PENDING = 'pending',
}

/**
 * Constants
 */

const DRAG_OFFSET_LIMIT = 60

const TOAST_X_OFFSET = typeof window !== 'undefined' ? Math.max(window?.innerWidth / 2, 600) : 0
const TOAST_ANIMATION_DURATION = 0.35

export const toastOptions = {
  motionVariants: {
    animate: {
      x: 0,
      transition: {
        duration: TOAST_ANIMATION_DURATION,
      },
    },
    initial: {
      x: TOAST_X_OFFSET,
    },
    exit: (dragDirection: number) => ({
      x: dragDirection ? getAnimationExitPosition(dragDirection) : TOAST_X_OFFSET,
      transition: {
        duration: TOAST_ANIMATION_DURATION,
      },
    }),
  },
}

const TOAST_TYPES: {
  [key: string]: {
    backgroundColor: string
    color: string
    dropShadow: string
  }
} = {
  [ToastType.FAILURE]: {
    backgroundColor: 'linear-gradient(118.15deg, rgba(232, 72, 85, 0.12) 0%, rgba(232, 72, 85, 0.04) 100%)',
    color: '#E84855',
    dropShadow: 'box-shadow: 0px 4px 16px rgba(232, 72, 85, 0.04)',
  },
  [ToastType.SUCCESS]: {
    backgroundColor: 'linear-gradient(118.15deg, rgba(86, 227, 159, 0.12) 0%, rgba(86, 227, 159, 0.04) 100%)',
    color: '#56E39F',
    dropShadow: 'box-shadow: 0px 4px 16px rgba(86, 227, 159, 0.04)',
  },
  [ToastType.PENDING]: {
    backgroundColor: 'linear-gradient(118.15deg, rgba(232, 72, 85, 0.12) 0%, rgba(232, 72, 85, 0.04) 100%)',
    color: 'text.secondary',
    dropShadow: 'box-shadow: 0px 4px 16px rgba(232, 72, 85, 0.04)',
  },
}

interface StatusIconProps {
  variant: Props['variant']
  color: string
}

const StatusIcon = ({ variant, color }: StatusIconProps) => {
  if (variant === ToastType.SUCCESS) {
    return <Icon as={HiOutlineCheckCircle} boxSize="24px" color={color} />
  }

  if (variant === ToastType.FAILURE) {
    return <Icon as={HiOutlineExclamationCircle} boxSize="24px" color={color} />
  }

  return <Spinner boxSize="24px" speed="2s" />
}

const Toast = ({ title, description, variant, onActionClick, onClose, actionLabel }: Props) => {
  const { t } = useTranslation('toast')

  const toastStyle = TOAST_TYPES[variant]

  const [dragOffset, setDragOffset] = useState(0)

  const handleDrag = (_event: PointerEvent, info: { offset: { x: number } }) => {
    setDragOffset(info.offset.x)
  }

  const handleDragEnd = () => {
    if (Math.abs(dragOffset) > DRAG_OFFSET_LIMIT) {
      onClose?.()
    }
  }

  const boxVariants = {
    closed: {
      x: getAnimationExitPosition(dragOffset),
      transition: {
        duration: 0.3,
      },
    },
  }

  return (
    <motion.div
      drag="x"
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      dragConstraints={{
        left: 0,
        right: 0,
      }}
      dragElastic={0.4}
      variants={boxVariants}
      exit={toastOptions.motionVariants.exit(dragOffset)}
    >
      <Box
        borderRadius="10px"
        boxShadow={`inset 0 0 0 1px ${theme.colors.stroke.divider}`}
        w={{ base: '100%', lg: '480px' }}
        maxW="480px"
        backdropFilter="auto"
        backdropBlur="16px"
        overflow="hidden"
      >
        <Box bgColor="background.hover" borderRadius="10px">
          <Box bgImage="/assets/noise-texture.png" h="full">
            <Box
              maxW="480px"
              borderRadius="10px"
              bgGradient={toastStyle.backgroundColor}
              dropShadow={toastStyle.dropShadow}
            >
              <Box py="16px" px="20px" position="relative">
                <HStack alignItems="flex-start">
                  <Box position="absolute" right="5px" top="5px">
                    <IconButton
                      background="none"
                      onClick={onClose}
                      icon={
                        <CloseIcon
                          w="10px"
                          h="10px"
                          color="#738795"
                          _hover={{ color: theme.colors.stroke.closeButtonHover }}
                        />
                      }
                      aria-label={'Close Menu'}
                      _hover={{ bgColor: 'transparent' }}
                      _active={{ bgColor: 'transparent' }}
                      _pressed={{ bgColor: 'transparent' }}
                    />
                  </Box>
                  <Box marginInlineStart="0 !important">
                    <StatusIcon variant={variant} color={toastStyle.color} />
                  </Box>
                  <Flex maxW="410px" direction="column" pl="11px" alignItems="flex-start" overflow="clip">
                    <Text
                      fontSize="16px"
                      textAlign="left"
                      fontWeight="700"
                      lineHeight="24px"
                      w="100%"
                      color={toastStyle.color}
                      maxW="200px"
                    >
                      {title}
                    </Text>
                    {description && (
                      <Text
                        maxW="410px"
                        maxH="200px"
                        fontSize="14px"
                        lineHeight="20px"
                        fontWeight="500"
                        color="text.secondary"
                      >
                        {description}
                      </Text>
                    )}
                    {onActionClick && (
                      <SmallButton textTransform="none" onClick={onActionClick} mt="12px">
                        {actionLabel || t('dashboard.success.addCToken')}
                      </SmallButton>
                    )}
                  </Flex>
                </HStack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </motion.div>
  )
}

export default Toast
