export const tokenImages: { [key: string]: string } = {
  IOTA: '/assets/token-images/MIOTA@2x.png',
  SMR: '/assets/token-images/SMR@2x.png',
  USDC: '/assets/token-images/USDC@2x.png',
  WETH: '/assets/token-images/ETH@2x.png',
  USDT: '/assets/token-images/USDT@2x.png',
  DEEPR: '/assets/token-images/deepr-icon@2x.png',
  WIOTA: '/assets/token-images/MIOTA@2x.png',

  ['USDC.E']: '/assets/token-images/USDC@2x.png',
  WUSDC: '/assets/token-images/USDC@2x.png',
  WMIOTA: '/assets/token-images/MIOTA@2x.png',
  WUSDT: '/assets/token-images/USDT@2x.png',
  WBTC: '/assets/token-images/wBTC@2x.png',

  FETH: '/assets/token-images/ETH@2x.png',
  FUSDC: '/assets/token-images/USDC@2x.png',
  FUSDT: '/assets/token-images/USDT@2x.png',
  FTM: 'https://assets-cdn.trustwallet.com/blockchains/binance/assets/FTM-A64/logo.png',
  FMIOTA: '/assets/token-images/MIOTA@2x.png',
  FBTC: '/assets/token-images/wBTC@2x.png',
}
