import { getDefaultConfig, wallets } from 'connectkit'
import { createConfig } from 'wagmi'
import { iota, iotaTestnet, shimmer, shimmerTestnet } from 'wagmi/chains'
import { safe } from 'wagmi/connectors'

import { SAFE_WALLET_SMR_DOMAIN } from '~/constants'

const walletConnectProjectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID || ''

const defaultConfig = getDefaultConfig({
  appName: 'Deepr',
  chains: [iota, shimmer, iotaTestnet, shimmerTestnet],
  walletConnectProjectId,
})

export const config = createConfig({
  ...defaultConfig,
  connectors: [
    ...(defaultConfig.connectors ?? []),
    wallets['bloom'],
    safe({
      allowedDomains: [SAFE_WALLET_SMR_DOMAIN],
      debug: false,
    }),
  ],
})
