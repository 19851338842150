export default [
  {
    type: 'constructor',
    payable: false,
    inputs: [
      { type: 'address', name: 'comptroller_' },
      { type: 'address', name: 'interestRateModel_' },
      { type: 'uint256', name: 'initialExchangeRateMantissa_' },
      { type: 'string', name: 'name_' },
      { type: 'string', name: 'symbol_' },
      { type: 'uint8', name: 'decimals_' },
      { type: 'address', name: 'admin_' },
    ],
  },
  { type: 'error', name: 'AcceptAdminPendingAdminCheck', inputs: [] },
  {
    type: 'error',
    name: 'AddReservesFactorFreshCheck',
    inputs: [{ type: 'uint256', name: 'actualAddAmount' }],
  },
  { type: 'error', name: 'BorrowCashNotAvailable', inputs: [] },
  { type: 'error', name: 'BorrowComptrollerRejection', inputs: [{ type: 'uint256', name: 'errorCode' }] },
  { type: 'error', name: 'BorrowFreshnessCheck', inputs: [] },
  {
    type: 'error',
    name: 'LiquidateAccrueBorrowInterestFailed',
    inputs: [{ type: 'uint256', name: 'errorCode' }],
  },
  {
    type: 'error',
    name: 'LiquidateAccrueCollateralInterestFailed',
    inputs: [{ type: 'uint256', name: 'errorCode' }],
  },
  { type: 'error', name: 'LiquidateCloseAmountIsUintMax', inputs: [] },
  { type: 'error', name: 'LiquidateCloseAmountIsZero', inputs: [] },
  { type: 'error', name: 'LiquidateCollateralFreshnessCheck', inputs: [] },
  { type: 'error', name: 'LiquidateComptrollerRejection', inputs: [{ type: 'uint256', name: 'errorCode' }] },
  { type: 'error', name: 'LiquidateFreshnessCheck', inputs: [] },
  { type: 'error', name: 'LiquidateLiquidatorIsBorrower', inputs: [] },
  {
    type: 'error',
    name: 'LiquidateRepayBorrowFreshFailed',
    inputs: [{ type: 'uint256', name: 'errorCode' }],
  },
  {
    type: 'error',
    name: 'LiquidateSeizeComptrollerRejection',
    inputs: [{ type: 'uint256', name: 'errorCode' }],
  },
  { type: 'error', name: 'LiquidateSeizeLiquidatorIsBorrower', inputs: [] },
  { type: 'error', name: 'MintComptrollerRejection', inputs: [{ type: 'uint256', name: 'errorCode' }] },
  { type: 'error', name: 'MintFreshnessCheck', inputs: [] },
  { type: 'error', name: 'RedeemComptrollerRejection', inputs: [{ type: 'uint256', name: 'errorCode' }] },
  { type: 'error', name: 'RedeemFreshnessCheck', inputs: [] },
  { type: 'error', name: 'RedeemTransferOutNotPossible', inputs: [] },
  { type: 'error', name: 'ReduceReservesAdminCheck', inputs: [] },
  { type: 'error', name: 'ReduceReservesCashNotAvailable', inputs: [] },
  { type: 'error', name: 'ReduceReservesCashValidation', inputs: [] },
  { type: 'error', name: 'ReduceReservesFreshCheck', inputs: [] },
  {
    type: 'error',
    name: 'RepayBorrowComptrollerRejection',
    inputs: [{ type: 'uint256', name: 'errorCode' }],
  },
  { type: 'error', name: 'RepayBorrowFreshnessCheck', inputs: [] },
  { type: 'error', name: 'SetComptrollerOwnerCheck', inputs: [] },
  { type: 'error', name: 'SetInterestRateModelFreshCheck', inputs: [] },
  { type: 'error', name: 'SetInterestRateModelOwnerCheck', inputs: [] },
  { type: 'error', name: 'SetPendingAdminOwnerCheck', inputs: [] },
  { type: 'error', name: 'SetReserveFactorAdminCheck', inputs: [] },
  { type: 'error', name: 'SetReserveFactorBoundsCheck', inputs: [] },
  { type: 'error', name: 'SetReserveFactorFreshCheck', inputs: [] },
  { type: 'error', name: 'TimestampPriorLastAccrual', inputs: [] },
  { type: 'error', name: 'TimestampTooLarge', inputs: [] },
  { type: 'error', name: 'TransferComptrollerRejection', inputs: [{ type: 'uint256', name: 'errorCode' }] },
  { type: 'error', name: 'TransferNotAllowed', inputs: [] },
  { type: 'error', name: 'TransferNotEnough', inputs: [] },
  { type: 'error', name: 'TransferTooMuch', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    name: 'AccrueInterest',
    inputs: [
      { type: 'uint256', name: 'cashPrior', indexed: false },
      { type: 'uint256', name: 'interestAccumulated', indexed: false },
      { type: 'uint256', name: 'borrowIndex', indexed: false },
      { type: 'uint256', name: 'totalBorrows', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'Approval',
    inputs: [
      { type: 'address', name: 'owner', indexed: true },
      { type: 'address', name: 'spender', indexed: true },
      { type: 'uint256', name: 'amount', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'Borrow',
    inputs: [
      { type: 'address', name: 'borrower', indexed: false },
      { type: 'uint256', name: 'borrowAmount', indexed: false },
      { type: 'uint256', name: 'accountBorrows', indexed: false },
      { type: 'uint256', name: 'totalBorrows', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'LiquidateBorrow',
    inputs: [
      { type: 'address', name: 'liquidator', indexed: false },
      { type: 'address', name: 'borrower', indexed: false },
      { type: 'uint256', name: 'repayAmount', indexed: false },
      { type: 'address', name: 'cTokenCollateral', indexed: false },
      { type: 'uint256', name: 'seizeTokens', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'Mint',
    inputs: [
      { type: 'address', name: 'minter', indexed: false },
      { type: 'uint256', name: 'mintAmount', indexed: false },
      { type: 'uint256', name: 'mintTokens', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'NewAdmin',
    inputs: [
      { type: 'address', name: 'oldAdmin', indexed: false },
      { type: 'address', name: 'newAdmin', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'NewComptroller',
    inputs: [
      { type: 'address', name: 'oldComptroller', indexed: false },
      { type: 'address', name: 'newComptroller', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'NewMarketInterestRateModel',
    inputs: [
      { type: 'address', name: 'oldInterestRateModel', indexed: false },
      { type: 'address', name: 'newInterestRateModel', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'NewPendingAdmin',
    inputs: [
      { type: 'address', name: 'oldPendingAdmin', indexed: false },
      { type: 'address', name: 'newPendingAdmin', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'NewReserveFactor',
    inputs: [
      { type: 'uint256', name: 'oldReserveFactorMantissa', indexed: false },
      { type: 'uint256', name: 'newReserveFactorMantissa', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'Redeem',
    inputs: [
      { type: 'address', name: 'redeemer', indexed: false },
      { type: 'uint256', name: 'redeemAmount', indexed: false },
      { type: 'uint256', name: 'redeemTokens', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'RepayBorrow',
    inputs: [
      { type: 'address', name: 'payer', indexed: false },
      { type: 'address', name: 'borrower', indexed: false },
      { type: 'uint256', name: 'repayAmount', indexed: false },
      { type: 'uint256', name: 'accountBorrows', indexed: false },
      { type: 'uint256', name: 'totalBorrows', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'ReservesAdded',
    inputs: [
      { type: 'address', name: 'benefactor', indexed: false },
      { type: 'uint256', name: 'addAmount', indexed: false },
      { type: 'uint256', name: 'newTotalReserves', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'ReservesReduced',
    inputs: [
      { type: 'address', name: 'admin', indexed: false },
      { type: 'uint256', name: 'reduceAmount', indexed: false },
      { type: 'uint256', name: 'newTotalReserves', indexed: false },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    name: 'Transfer',
    inputs: [
      { type: 'address', name: 'from', indexed: true },
      { type: 'address', name: 'to', indexed: true },
      { type: 'uint256', name: 'amount', indexed: false },
    ],
  },
  {
    type: 'function',
    name: 'NO_ERROR',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_acceptAdmin',
    constant: false,
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_addReserves',
    constant: false,
    stateMutability: 'payable',
    payable: true,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_reduceReserves',
    constant: false,
    payable: false,
    inputs: [{ type: 'uint256', name: 'reduceAmount' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_setComptroller',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'newComptroller' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_setInterestRateModel',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'newInterestRateModel' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_setPendingAdmin',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'newPendingAdmin' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: '_setReserveFactor',
    constant: false,
    payable: false,
    inputs: [{ type: 'uint256', name: 'newReserveFactorMantissa' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'accrualBlockTimestamp',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'accrueInterest',
    constant: false,
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'admin',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'address' }],
  },
  {
    type: 'function',
    name: 'allowance',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [
      { type: 'address', name: 'owner' },
      { type: 'address', name: 'spender' },
    ],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'approve',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'spender' },
      { type: 'uint256', name: 'amount' },
    ],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'balanceOf',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [{ type: 'address', name: 'owner' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'balanceOfUnderlying',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'owner' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'borrow',
    constant: false,
    payable: false,
    inputs: [{ type: 'uint256', name: 'borrowAmount' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'borrowBalanceCurrent',
    constant: false,
    payable: false,
    inputs: [{ type: 'address', name: 'account' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'borrowBalanceStored',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [{ type: 'address', name: 'account' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'borrowIndex',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'borrowRatePerSecond',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'comptroller',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'address' }],
  },
  {
    type: 'function',
    name: 'decimals',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint8' }],
  },
  {
    type: 'function',
    name: 'exchangeRateCurrent',
    constant: false,
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'exchangeRateStored',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'getAccountSnapshot',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [{ type: 'address', name: 'account' }],
    outputs: [{ type: 'uint256' }, { type: 'uint256' }, { type: 'uint256' }, { type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'getCash',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'initialize',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'comptroller_' },
      { type: 'address', name: 'interestRateModel_' },
      { type: 'uint256', name: 'initialExchangeRateMantissa_' },
      { type: 'string', name: 'name_' },
      { type: 'string', name: 'symbol_' },
      { type: 'uint8', name: 'decimals_' },
    ],
    outputs: [],
  },
  {
    type: 'function',
    name: 'interestRateModel',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'address' }],
  },
  {
    type: 'function',
    name: 'isCToken',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'liquidateBorrow',
    constant: false,
    stateMutability: 'payable',
    payable: true,
    inputs: [
      { type: 'address', name: 'borrower' },
      { type: 'address', name: 'cTokenCollateral' },
    ],
    outputs: [],
  },
  {
    type: 'function',
    name: 'mint',
    constant: false,
    stateMutability: 'payable',
    payable: true,
    inputs: [],
    outputs: [],
  },
  {
    type: 'function',
    name: 'name',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'string' }],
  },
  {
    type: 'function',
    name: 'pendingAdmin',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'address' }],
  },
  {
    type: 'function',
    name: 'protocolSeizeShareMantissa',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'redeem',
    constant: false,
    payable: false,
    inputs: [{ type: 'uint256', name: 'redeemTokens' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'redeemUnderlying',
    constant: false,
    payable: false,
    inputs: [{ type: 'uint256', name: 'redeemAmount' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'repayBorrow',
    constant: false,
    stateMutability: 'payable',
    payable: true,
    inputs: [],
    outputs: [],
  },
  {
    type: 'function',
    name: 'repayBorrowBehalf',
    constant: false,
    stateMutability: 'payable',
    payable: true,
    inputs: [{ type: 'address', name: 'borrower' }],
    outputs: [],
  },
  {
    type: 'function',
    name: 'reserveFactorMantissa',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'seize',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'liquidator' },
      { type: 'address', name: 'borrower' },
      { type: 'uint256', name: 'seizeTokens' },
    ],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'supplyRatePerSecond',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'symbol',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'string' }],
  },
  {
    type: 'function',
    name: 'totalBorrows',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'totalBorrowsCurrent',
    constant: false,
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'totalReserves',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'totalSupply',
    constant: true,
    stateMutability: 'view',
    payable: false,
    inputs: [],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'transfer',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'dst' },
      { type: 'uint256', name: 'amount' },
    ],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'transferFrom',
    constant: false,
    payable: false,
    inputs: [
      { type: 'address', name: 'src' },
      { type: 'address', name: 'dst' },
      { type: 'uint256', name: 'amount' },
    ],
    outputs: [{ type: 'bool' }],
  },
] as const
