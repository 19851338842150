import {
  Badge,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { useSwitchChain } from 'wagmi'

import { networkIcons } from '~/constants/networkImages'
import { SUPPORTED_NETWORKS } from '~/constants/tokens'
import { useHydratedAccount } from '~/hooks/useHydratedAccount'
import * as Analytics from '~/lib/analytics'
import { useTranslation } from '~/lib/i18n'
import { NetworksNames } from '~/lib/networks'
import CloseModalButton from './CloseModalButton'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const NetworkModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('layout')
  const { switchChainAsync, isPending, variables } = useSwitchChain()
  const { chainId: connectedChainId } = useHydratedAccount()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: 'full', md: 'md' }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={{ base: '16px', md: '20px' }}>
          {t('networkModal.title')}
          <CloseModalButton />
        </ModalHeader>
        <ModalBody p={6}>
          <Stack spacing={4}>
            {SUPPORTED_NETWORKS.map((network) => {
              const selectedNetwork = isPending && variables?.chainId ? variables?.chainId : connectedChainId

              return (
                <Button
                  onClick={async () => {
                    if (network === connectedChainId) {
                      return
                    }

                    try {
                      await switchChainAsync({ chainId: network })
                    } catch (e) {
                      const errorMessage = (e as Error)?.message
                      if (errorMessage.includes('User Rejected the request')) {
                        return
                      }

                      Analytics.trackException(e)
                    }
                  }}
                  key={network}
                  justifyContent="flex-start"
                  size="lg"
                  px={4}
                >
                  <Flex gap={2} alignItems="center">
                    <Image boxSize="24px" src={networkIcons[network].transparent} />
                    <Text fontSize="16px" lineHeight="24px" fontWeight={700}>
                      {NetworksNames[network]}
                    </Text>
                    <AnimatePresence>
                      {selectedNetwork === network && (
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                          <Badge ml={2} fontSize="2xs" p={1}>
                            {isPending ? (
                              <Flex gap={1}>
                                <Spinner size="xs" />
                                {t('networkModal.approve')}
                              </Flex>
                            ) : (
                              t('networkModal.connected')
                            )}
                          </Badge>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Flex>
                </Button>
              )
            })}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NetworkModal
